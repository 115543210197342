(function(){
	'use strict';

	angular
		.module('core')
		.directive('phevCostInfo', phevCostInfo);

	phevCostInfo.$inject = [];

	function phevCostInfo() {

		var directive = {
			restrict: 'E',
			templateUrl: './modules/results/views/costInfo.client.view.html',
			scope: {
				'data': '=',
				symbol: '='
			}
		};

		return directive;

		/////////////////

	}

})();
