(function() {
	'use strict';

	angular
		.module('locations')
		.factory('Directions', Directions);

	Directions.$inject = ['$q', 'uiGmapGoogleMapApi'];

	function Directions($q, uiGmapGoogleMapApi) {

		var googleMaps;
		var service, renderer;
        var options = {
            suppressMarkers: true
        };

		uiGmapGoogleMapApi.then(function(maps) {
			googleMaps = maps;
			service = new googleMaps.DirectionsService();
            renderer = new googleMaps.DirectionsRenderer(options);
    	});

		var factory = {
			roundTrip: roundTrip,
            showRoute: showRoute,
            setMap: setMap,
		};

		return factory;

		////////////

    	function roundTrip(start, end) {

    		var deferred = $q.defer();

            var directionsRequest = {
                origin: start.geometry.location,
                destination: start.geometry.location,
                waypoints: [{location: end.geometry.location}],
                travelMode: googleMaps.DirectionsTravelMode.DRIVING
            };

            service.route(directionsRequest, function(response, status) {
                if(status === googleMaps.DirectionsStatus.OK) {
                    deferred.resolve(response);
                }
                else {
                    deferred.reject();
                }
            });

    		return deferred.promise;
    	}

        function showRoute(result) {
            renderer.setDirections(result);
        }

        function setMap(map) {
            renderer.setMap(map);
        }

	}

})();
