(function() {
	'use strict';

	angular
		.module('core')
		.config(Config);

	Config.$inject = ['$routeProvider'];

	function Config($routeProvider) {
		$routeProvider
			.when('/', {
				template: '',
				controller: 'Index',
				controllerAs: 'vm'
			});
	}

})();