(function() {
	'use strict';

	angular
		.module('locations')
		.controller('Picker', PickerController);

	PickerController.$inject = ['$rootScope', '$scope', '$location', 'uiGmapGoogleMapApi', 'Settings', 'uiGmapIsReady'];

	function PickerController($rootScope, $scope, $location, uiGmapGoogleMapApi, Settings, uiGmapIsReady) {

		var gmap,
			autocomplete,
			places,
			vm = this;

		vm.askResults  = false;
		vm.continue = false;
		vm.results = false;
		vm.search = search;
		vm.step2 = step2;
		vm.step3 = step3;

		$rootScope.init.then(activate);
		$scope.$watch(getBounds, updateBounds, true);
		$scope.$watch(getOrigin, updateOrigin, true);
		$scope.$watch(getDest, updateDest, true);

		// Initialize search box once the map is loaded
		uiGmapGoogleMapApi.then(function(maps) {

			// Save a reference to the API
			gmap = maps;

			var pickerName;
			if($location.path() === '/locations/start') {
				pickerName = 'picker-origin';
			}
			else {
				pickerName = 'picker-dest';
			}

			autocomplete = new gmap.places.Autocomplete(document.getElementById(pickerName), {
				fields: ['formatted_address', 'geometry'],
			});

			// Set current bounds
			var bounds = Settings.getBounds();
			if(bounds) {
				autocomplete.setBounds(bounds);
			}

			autocomplete.addListener('place_changed', placeChanged);
    	});

		// Places service requires the map object
    	uiGmapIsReady.promise(1).then(function(instances) {
			places = new gmap.places.PlacesService(instances[0].map);
		});

		////////////


		function activate() {
			$rootScope.$emit('hideResults');

			if($location.path() === '/locations/end' && !Settings.getOrigin()) {
				$location.path('/locations/start');
			}
		}

		function getOrigin() {
			return Settings.getOrigin();
		}

		function getDest() {
			return Settings.getDest();
		}

		function getBounds() {
			return Settings.getBounds();
		}

		function updateBounds(newVal) {
			if(newVal && autocomplete) {
				autocomplete.setBounds(newVal);
			}
		}

		function updateOrigin(newVal, oldVal) {
			if(newVal !== oldVal) {
				vm.continue = true;
			}
		}


		function updateDest(newVal, oldVal) {
			if(newVal !== oldVal) {
				vm.askResults = true;
			}
		}

		function placeChanged() {
			var place = autocomplete.getPlace();

			if(place) {
				updateLocation(place);
			}
		}

		function updateLocation(place) {
			if($location.path() === '/locations/start') {
				Settings.setOrigin(place);
				$scope.$apply();
			}
			else if($location.path() === '/locations/end') {
				Settings.setDest(place);
				$scope.$apply();
			}
		}

		function step2() {
			if(!Settings.getDest()) {
				$location.path('/locations/end');
			}
			else {
				$location.path('/');
			}
		}

		function step3() {
			$rootScope.$emit('enableResults');
			$location.path('/');
		}

		function search() {
			if(vm.query) {
				var request = {
					bounds: Settings.getBounds(),
					query: vm.query,
				};

				places.textSearch(request, handleTextSearch);
			}
		}

		function handleTextSearch(results, status) {
			if(status === 'OK') {
				var request = {
					placeId: results[0].place_id
				};

				places.getDetails(request, handlePlaceDetails);
			}
		}

		function handlePlaceDetails(result, status) {
			if(status === 'OK') {
				updateLocation(result);
			}
		}
	}

})();
