(function() {
	'use strict';

	angular
		.module('core')
		.factory('Location', Location);

	Location.$inject = ['$http'];

	function Location($http) {

		var location;

		var factory = {
			getLocation: getLocation
		};

		return factory;

		////////////

		function getLocation() {
			return $http.get('./api/location').then(function(res) {
				location = res.data;
				return location;
			});
		}
	}

})();
