(function() {
	'use strict';

	angular
		.module('instructions')
		.config(Config);

	Config.$inject = ['$routeProvider'];

	function Config($routeProvider) {
		$routeProvider
			.when('/instructions', {
				templateUrl: './modules/instructions/views/instructions.client.view.html',
				controller: 'Instructions',
				controllerAs: 'vm'
			})
			.when('/instructions/:slide', {
				templateUrl: './modules/instructions/views/instructions.client.view.html',
				controller: 'Instructions',
				controllerAs: 'vm'
			});
	}

})();