(function() {
	'use strict';

	var dependencies = [
		'ngRoute',
		'ui.bootstrap',
		'core',
		'prices',
		'commute',
		'cars',
		'locations',
		'sidebar',
		'instructions',
		'export',
		'optin',
		'results',
		'ngAnimate'
	];

	angular.module('evexplorer', dependencies).config(Config);

	Config.$inject = ['$locationProvider'];

	function Config($locationProvider) {
		$locationProvider.hashPrefix('!');
	}

	// Bootstrap angular
	angular.element(document).ready(function() {
		angular.bootstrap(document, ['evexplorer']);
	});

	angular.module('evexplorer').run(initialize);

	initialize.$inject = ['$q', '$rootScope', 'Location', 'Prices', 'Settings', 'Cars', '$location', 'Converter'];

	function initialize($q, $rootScope, Location, Prices, Settings, Cars, $location, Converter) {

		$rootScope.init = $q.all([
			initLocation($q, Location, Settings, Prices),
			initCars($q, Settings, Cars, $location),
		]);

		$rootScope.init.finally(function() {
			// Handle currency passed in through the URL
			if($location.search().currency && Converter.isValid($location.search().currency)) {
				Settings.setCurrency($location.search().currency);
			}

			// Handle pricing passed in through the URL
			if($location.search().prices) {
				var prices = $location.search().prices.split(',');

				if(prices.length === 5) {
					var valid = true;

					for(var i = 0; i < 5; i++) {
						prices[i] = parseFloat(prices[i]);
						if(isNaN(prices[i])) {
							valid = false;
						}
					}

					if(valid) {
						Settings.setPrices({
							electricity: 	prices[0],
							regular: 		prices[1],
							super: 			prices[2],
							premium: 		prices[3],
							diesel: 		prices[4],
						});
					}
				}
			}

		});
	}

	// Get the user's location and location based pricing
	function initLocation($q, Location, Settings, Prices) {
		var deferred = $q.defer();

		var default_location = {
			'region_code': 'US',
			'country_name': 'United States',
			'latitude':37.751,
			'longitude':-97.822
		};

		Location.getLocation()
			.then(function(data) {
				if(data.region_code === null) {
					Settings.setLocation(default_location);
					Settings.setDefaultZoom(5);
				}
				else {
					Settings.setLocation(data);
				}
			},
			function() {
				Settings.setLocation(default_location);
			})
			.finally(function() {
				Prices.getPrices(Settings.getState()).then(function(prices) {
					Settings.setPrices(prices);
					deferred.resolve();
				}, function() {
					deferred.resolve();
				});
			});

		return deferred.promise;
	}

	// Populate the default cars
	// Handle if the initial cars are passed by URL also
	function initCars($q, Settings, Cars, $location) {

		var cars, promises = [];

		if($location.search().carmanagercars) {
			cars = $location.search().carmanagercars.split(',');

			for(var j = 0, len = cars.length; j < len; j++) {
				cars[j] = parseInt(cars[j]);
			}
		}
		else {
			cars = Settings.getDefaultCars();
		}

		for(var i = 0, leni = cars.length; i < leni; i++) {
			promises.push(Cars.getCar(cars[i]));
		}

		return $q.all(promises).then(function(results) {
			for(var j = 0, lenj = results.length; j < lenj; j++) {
				Settings.setCar(j, results[j]);
			}
		});
	}

})();
