(function() {
	'use strict';

	angular
		.module('locations')
		.controller('Static', StaticController);

	StaticController.$inject = ['$rootScope'];

	function StaticController($rootScope) {

		activate();

		////////////

		function activate() {
			$rootScope.$emit('hideResults');
		}
	}

})();
