(function() {
	'use strict';

	angular
		.module('cars')
		.controller('Cars', CarsController);

	CarsController.$inject = ['$rootScope', '$routeParams', '$location', 'Settings', 'Cars'];

	function CarsController($rootScope, $routeParams, $location, Settings, Cars) {

		var vm = this;

		vm.numCars = 4;
		vm.carsPerPage = 1;

		vm.changeCars = changeCars;
		vm.pick = pick;
		vm.showCar = showCar;
		vm.updateCarSettings = updateCarSettings;
		vm.closePopup = closePopup;

		vm.changeYear = changeYear;
		vm.changeMake = changeMake;
		vm.changeModel = changeModel;
		vm.updateCar = updateCar;

		$rootScope.init.then(activate);

		////////////

		function activate() {
			if($routeParams.carId) {
				vm.currentCar = $routeParams.carId;
			}
			else {
				vm.currentCar = 1;
			}
			vm.cars = Settings.getCars();
			vm.car = Settings.getCar(vm.currentCar-1);
			vm.units = Settings.getUnits();

			showCar();
		}

		function showCar() {
			reset();
			vm.template = 'modules/cars/views/car.client.view.html';
		}

		function closePopup() {
			$location.path('/');
		}

		function changeCars(index) {
			if(typeof index !== 'undefined') {
				vm.currentCar = index+1;
				vm.car = Settings.getCar(index);
			}
			else{
				vm.car = Settings.getCar(vm.currentCar-1);
			}
			reset();
		}

		function pick() {
			vm.template = 'modules/cars/views/picker.client.view.html';

			vm.year = null;
			vm.years = null;
			vm.make = null;
			vm.makes = null;
			vm.model = null;
			vm.models = null;
			vm.option = null;
			vm.options = null;

			Cars.getYears().then(function(years) {
				vm.years = years;
			});
		}

		function updateCarSettings() {
			vm.car.fuelEconomy = vm.carSettings.fuelEconomy;
			vm.car.elecRange = vm.carSettings.elecRange;
			vm.car.lvl2chargeTime = vm.carSettings.lvl2chargeTime;

			Settings.updateCar(vm.currentCar-1, vm.car);
		}

		function reset() {
			vm.carSettings = {
				fuelEconomy: vm.car.fuelEconomy,
				elecRange: vm.car.elecRange,
				lvl2chargeTime: vm.car.lvl2chargeTime,
			};
		}

		function changeYear() {

			vm.make = null;
			vm.makes = null;
			vm.model = null;
			vm.models = null;
			vm.option = null;
			vm.options = null;

			Cars.getMakes(vm.year).then(function(makes) {
				vm.makes = makes;
			});
		}

		function changeMake() {
			vm.model = null;
			vm.models = null;
			vm.option = null;
			vm.options = null;

			Cars.getModels(vm.year, vm.make).then(function(models) {
				vm.models = models;
			});
		}

		function changeModel() {
			vm.option = null;
			vm.options = null;

			Cars.getOptions(vm.year, vm.make, vm.model).then(function(options) {
				vm.options = options;

				if(vm.options.length === 1) {
					vm.option = vm.options[0].id;
				}
			});
		}

		function updateCar() {
			if(vm.option) {
				Cars.getCar(vm.option).then(function(car) {
					Settings.setCar(vm.currentCar-1, car);
					vm.car = Settings.getCar(vm.currentCar-1);
					showCar();
				});
			}
		}

	}

})();
