(function() {
	'use strict';

	angular
		.module('cars')
		.factory('Cars', Cars);

	Cars.$inject = ['$http'];

	function Cars($http) {

		var factory = {
            getCar: getCar,
            getYears: getYears,
            getMakes: getMakes,
            getModels: getModels,
            getOptions: getOptions
        };

		return factory;

		////////////

		function getCar(id) {
			return $http.get('./api/vehicles/details/'+id).then(function(result) {
				return result.data.vehicle;
			});
		}

		function getYears() {
			return $http.get('./api/vehicles/years').then(function(result) {
				return result.data.years;
			});
		}

		function getMakes(year) {
			return $http.get('./api/vehicles/makes/'+year).then(function(result) {
				return result.data.makes;
			});
		}

		function getModels(year, make) {
			return $http.get('./api/vehicles/models/'+year+'/'+make).then(function(result) {
				return result.data.models;
			});
		}

		function getOptions(year, make, model) {
			return $http.get('./api/vehicles/options/'+year+'/'+make+'/'+model).then(function(result) {
				return result.data.options;
			});
		}
	}

})();
