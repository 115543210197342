(function() {
	'use strict';

	angular
		.module('core')
		.controller('Index', IndexController);

	IndexController.$inject = ['$rootScope', '$location', 'Settings'];

	function IndexController($rootScope, $location, Settings) {

		$rootScope.init.then(activate);

		////////////

		function activate() {

			$rootScope.$emit('showResults');

			if(!Settings.getOrigin()) {
				$location.path('/locations/start');
			}
			else if(!Settings.getDest()) {
				$location.path('/locations/end');
			}
		}

	}

})();
