(function() {
	'use strict';

	angular
		.module('cars')
		.config(Config);

	Config.$inject = ['$routeProvider'];

	function Config($routeProvider) {
		$routeProvider
			.when('/cars', {
				templateUrl: './modules/cars/views/cars.client.view.html',
				controller: 'Cars',
				controllerAs: 'vm'
			})
			.when('/cars/:carId', {
				templateUrl: './modules/cars/views/cars.client.view.html',
				controller: 'Cars',
				controllerAs: 'vm'
			});
	}

})();