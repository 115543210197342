(function() {
	'use strict';

	angular
		.module('locations')
		.controller('Map', MapController);

	MapController.$inject = ['$timeout', '$rootScope', '$scope', '$location', 'Settings', 'Geocoder', 'Directions'];

	function MapController($timeout, $rootScope, $scope, $location, Settings, Geocoder, Directions) {

		var vm = this;
		var timer;

		vm.map = {
			options: {
				scrollwheel: true,
				mapTypeControl: false,
			},
			events: {
				click: mapClicked,
				'bounds_changed': boundsChanged,
				'tilesloaded': mapLoaded,
			},
			control: {},
			pan: true,
		};

		vm.startMarker = {
			id: 'start',
			coords: {},
			options: {
				draggable: true,
				icon: './images/icons/home.png',
			},
			events: {
				dragend: markerDragged
			}
		};

		vm.endMarker = {
			id: 'end',
			coords: {},
			options: {
				draggable: true,
				icon: './images/icons/work.png',
			},
			events: {
				dragend: markerDragged
			}
		};

		$rootScope.init.then(activate);
		$rootScope.$on('$routeChangeSuccess', updateDraggable);
		$rootScope.$on('$routeChangeSuccess', updateZoom);
		$scope.$watch(getOrigin, updateOrigin);
		$scope.$watch(getDest, updateDest);

		$rootScope.$on('resultsReady', resultsReady);

		$scope.$on('$destroy', function() {
			$timeout.cancel(timer);
		});

		////////////

		function resultsReady() {
			Directions.showRoute(Settings.getRoute());

			// Refresh the map at the new center
			// Needed for the smaller layout where the map changes
			// from full screen to half screen
			timer = $timeout(function() {
				var center = vm.map.control.getGMap().getCenter();
				vm.map.control.refresh({
					latitude: center.lat(),
					longitude: center.lng()
				});
			});
		}


		function mapLoaded() {
			$rootScope.$emit('loaded');
		}

		function updateZoom() {

			if(vm.map.control.getGMap) {

				var map = vm.map.control.getGMap();

				if($location.path() === '/locations/end' && Settings.getOrigin() && !Settings.getDest()) {
					map.setCenter({
						lat: vm.startMarker.coords.latitude,
						lng: vm.startMarker.coords.longitude
					});
					map.setZoom(12);
				}
			}
		}

		function updateDraggable() {
			if($location.path() === '/locations/start') {
				vm.startMarker.options.draggable = true;
				vm.endMarker.options.draggable = false;
			}
			else if($location.path() === '/locations/end') {
				vm.startMarker.options.draggable = false;
				vm.endMarker.options.draggable = true;
			}
			else {
				vm.startMarker.options.draggable = false;
				vm.endMarker.options.draggable = false;
			}
		}

		function getOrigin() {
			return Settings.getOrigin();
		}

		function getDest() {
			return Settings.getDest();
		}

		function updateOrigin(newVal) {
			if(newVal) {
				vm.origin = newVal;
				vm.startMarker.coords = {
					latitude: newVal.geometry.location.lat(),
					longitude: newVal.geometry.location.lng(),
				};
				updateRoute();
			}
		}

		function updateDest(newVal) {
			if(newVal) {
				vm.dest = newVal;
				vm.endMarker.coords = {
					latitude: newVal.geometry.location.lat(),
					longitude: newVal.geometry.location.lng(),
				};
				updateRoute();
			}
		}

		function boundsChanged(map) {
			Settings.setBounds(map.getBounds());
		}

		function activate() {
			vm.map.center = Settings.getLocation();
			vm.map.zoom = Settings.getDefaultZoom();
		}

		function markerDragged(marker, event, model, args) {
			if(marker.key === 'start') {
				geocodeOrigin(args[0].latLng.lat(), args[0].latLng.lng());
			}
			else if(marker.key === 'end') {
				geocodeDest(args[0].latLng.lat(), args[0].latLng.lng());
			}
		}

		function geocodeOrigin(lat, lng) {
			Geocoder.reverse(lat, lng).then(function(place) {
				Settings.setOrigin(place);
				updateRoute();
			});
		}

		function geocodeDest(lat, lng) {
			Geocoder.reverse(lat, lng).then(function(place) {
				Settings.setDest(place);
				updateRoute();
			});
		}

		function updateRoute() {
			vm.origin = Settings.getOrigin();
			vm.dest = Settings.getDest();

			if(vm.origin && vm.dest) {
				Directions.roundTrip(vm.origin, vm.dest).then(function(result) {

					//Move the markers to the actual start/end location for the directions
					vm.startMarker.coords = {
						latitude: result.routes[0].legs[0].start_location.lat(),
						longitude: result.routes[0].legs[0].start_location.lng(),
					};
					vm.endMarker.coords = {
						latitude: result.routes[0].legs[0].end_location.lat(),
						longitude: result.routes[0].legs[0].end_location.lng(),
					};

					Settings.setRoute(result);
					Directions.setMap(vm.map.control.getGMap());
				});
			}
		}

		function mapClicked(map, event, args) {
			var newCoords = {
				latitude: args[0].latLng.lat(),
				longitude: args[0].latLng.lng()
			};

			if($location.path() === '/locations/start') {
				vm.startMarker.coords = newCoords;
				geocodeOrigin(newCoords.latitude, newCoords.longitude);
			}
			else if($location.path() === '/locations/end') {
				vm.endMarker.coords = newCoords;
				geocodeDest(newCoords.latitude, newCoords.longitude);
			}
		}
	}

})();
