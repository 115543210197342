(function() {
	'use strict';

	angular
		.module('instructions')
		.factory('Feedback', Feedback);

	Feedback.$inject = ['$http'];

	function Feedback($http) {

		var sent = false;
		var error = false;

		var factory = {
			send: send,
			isSent: isSent,
			isError: isError,
		};

		return factory;

		////////////

		function send(message) {
			if(!error) {
				var data = {
					message: message
				};

				var promise = $http.post('/api/feedback', data).then(function() {
					sent = true;
				}, function() {
					sent = true;
					error = true;
				});

				return promise;
			}
		}

		function isSent() {
			return sent;
		}

		function isError() {
			return error;
		}
	}

})();
