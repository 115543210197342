(function() {
	'use strict';

	angular
		.module('export')
		.factory('Export', Export);

	Export.$inject = ['Settings', 'Calcs', 'Converter'];

	function Export(Settings, Calcs, Converter) {

		var separator = '\t';
		var newline = '\n';

		var factory = {
			getSettingsData: getSettingsData,
			getCarData: getCarData,
		};

		return factory;

		////////////

		function getSettingsData() {

			var currency = Converter.getCurrency(Settings.getCurrency());
			var units = Settings.getUnits();

			var roundTrip = Calcs.getRoundTrip();
			var numTrips = Calcs.getNumTrips();
			var commute = Settings.getCommute();
			var origin = Settings.getOrigin();
			var dest = Settings.getDest();
			var prices = Settings.getPrices();

			var headers = [
				'Setting',
				'Value',
			];

			var settingsRows = [
				['Start Address', origin.formatted_address],
				['Destination Address', dest.formatted_address],
				['Round Trip Distance ('+units.distance+')', roundTrip.toFixed(2)],

				['Commute Frequency', commute.frequency],
				['Commute Period', commute.period],
				['Trips Per Year', numTrips],

				['Destination Charger', commute.destCharger],
				['Destination Charger Type', commute.destType],
				['Destination Charger Time (hours)', commute.destHours],
				['Destination Charger Price ('+currency.symbol+')', commute.destPrice],
				['Destination Charger Price Per', commute.destPer],

				['Home Charger', commute.homeCharger],

				['Currency', currency.code],
				['State', Settings.getState()],

				['Electricity Price ('+currency.symbol+'/kWh)', prices.electricity],
				['Regular Gasoline Price ('+currency.symbol+'/'+currency.unit+')', prices.regular],
				['Super Gasoline Price ('+currency.symbol+'/'+currency.unit+')', prices.super],
				['Premium Gasoline Price ('+currency.symbol+'/'+currency.unit+')', prices.premium],
				['Diesel Price ('+currency.symbol+'/'+currency.unit+')', prices.diesel],
			];

			var rows = [headers.join(separator)];

			for(var i = 0, len = settingsRows.length; i < len; i++) {
				rows.push(settingsRows[i].join(separator));
			}

			return rows.join(newline);
		}

		function getCarData() {

			var rows = [];
			var currency = Converter.getCurrency(Settings.getCurrency());
			var units = Settings.getUnits();

			var headers = [
				'ID',
				'Year',
				'Make',
				'Model',
				'Alt Vehicle Type',

				'Fuel Type',

				'Fuel Economy 1 ('+units.economy+')',
				'Fuel Economy 2 ('+units.economy+')',
				
				'Time to Charge @ 240V (hours)',
				'Electric Range ('+units.distance+')',

				'Home Elec Price ('+currency.symbol+'/kWh)',
				'Home Elec Used (kWh)',
				'Home Elec Cost ('+currency.symbol+')',

				'Work Elec Price ('+currency.symbol+'/kWh)',
				'Work Elec Used (kWh)',
				'Work Elec Cost ('+currency.symbol+')',

				'Total Elec Used (kWh)',
				'Total Elec Cost ('+currency.symbol+')',

				'Fuel Price ('+currency.symbol+'/'+currency.unit+')',
				'Fuel Volume ('+currency.unit+')',
				'Fuel Cost ('+currency.symbol+')',
			];

			rows.push(headers.join(separator));

			var cars = [
				Settings.getCar(0),
				Settings.getCar(1),
				Settings.getCar(2),
				Settings.getCar(3),
			];

			var costData = Calcs.getCostData();

			for(var i = 0, len = cars.length; i < len; i++) {
				rows.push([
					cars[i].id,
					cars[i].year,
					cars[i].make,
					cars[i].model,
					cars[i].type,

					cars[i].fuelType,

					cars[i].fuelEconomy,
					cars[i].fuelEconomy2,

					cars[i].lvl2chargeTime,
					cars[i].elecRange,

					costData[i].homeElecPrice.toFixed(3),
					costData[i].homeElecKwh.toFixed(2),
					costData[i].homeElecTotal.toFixed(2),

					costData[i].workElecPrice.toFixed(2),
					costData[i].workElecKwh.toFixed(2),
					costData[i].workElecTotal.toFixed(2),

					(costData[i].homeElecKwh + costData[i].workElecKwh).toFixed(2),
					(costData[i].homeElecTotal + costData[i].workElecTotal).toFixed(2),

					costData[i].fuelPrice.toFixed(2),
					costData[i].fuelVolume.toFixed(2),
					costData[i].fuelTotal.toFixed(2),
				].join(separator));
			}

			return rows.join(newline);
		}
	}

})();
