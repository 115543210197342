(function() {
	'use strict';

	angular
		.module('export')
		.config(Config);

	Config.$inject = ['$routeProvider'];

	function Config($routeProvider) {
		$routeProvider
			.when('/export', {
				templateUrl: './modules/export/views/export.client.view.html',
				controller: 'Export',
				controllerAs: 'vm'
			});
	}

})();