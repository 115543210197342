(function() {
	'use strict';

	angular
		.module('prices')
		.factory('Prices', Prices);

	Prices.$inject = ['$http', '$q'];

	function Prices($http, $q) {

		var factory = {
			getPrices: getPrices
		};

		return factory;

		////////////

		function getPrices(state) {
			var promises = [
				getRegular(state),
				getSuper(state),
				getPremium(state),
				getDiesel(state),
				getElectricity(state),
			];

			return $q.all(promises).then(function(results) {
				return {
					regular: results[0],
					super: results[1],
					premium: results[2],
					diesel: results[3],
					electricity: results[4],
				};
			});
		}

		function getRegular(state) {
			return $http.get('./api/prices/'+state+'/regular').then(function(result) {
				return result.data.price;
			});
		}

		function getSuper(state) {
			return $http.get('./api/prices/'+state+'/super').then(function(result) {
				return result.data.price;
			});
		}

		function getPremium(state) {
			return $http.get('./api/prices/'+state+'/premium').then(function(result) {
				return result.data.price;
			});
		}

		function getDiesel(state) {
			return $http.get('./api/prices/'+state+'/diesel').then(function(result) {
				return result.data.price;
			});
		}

		function getElectricity(state) {
			return $http.get('./api/prices/'+state+'/electricity').then(function(result) {
				return result.data.price;
			});
		}
	}
})();
