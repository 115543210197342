(function() {
	'use strict';

	angular
		.module('core')
		.directive('phevRangeBar', phevRangeBar);

	phevRangeBar.$inject = ['d3Service'];

	function phevRangeBar(d3Service) {

		var directive = {
			restrict: 'EA',
			link: link,
			scope: {
				data: '=',
				label: '@',
				onClick: '&',
				unit: '='
			}
		};

		return directive;

		////////////

		function link(scope, element) {
			d3Service.d3().then(function(d3) {

				//Create necessary elements
				var svg = d3.select(element[0])
							.append('svg')
							.attr('width', '100%')
							.attr('height', 42);

				var div = d3.select(element[0])
							.append('div')
							.attr('class', 'tooltip')
							.style('opacity', 0);

				//Watch for resizing (window / angular) or data changing
				window.onresize = function() {
					scope.$apply();
				};
				scope.$watch(function() {
					return angular.element(window)[0].innerWidth;
				}, function(newVal, oldVal) {
					if(newVal !== oldVal) {
						scope.render(scope.data, scope.unit);
					}
				});

				scope.$watch('data', function() {
					scope.render(scope.data, scope.unit);
				}, true);

				scope.$watch(function() {
					return svg.nodes()[0].getBoundingClientRect().width;
				}, function(newVal, oldVal) {
					if(newVal !== oldVal) {
						scope.render(scope.data, scope.unit);
					}
				});

				//Render the chart
				scope.render = function(data, unit) {

					//Clear existing
					svg.selectAll('*').remove();

					var padding = 15,
						height = 14,
						y = 10,
						width = svg.nodes()[0].getBoundingClientRect().width;

					if(width <= 0) {
						return;
					}

					//Calculate the maximum range of the EVs we have
					var maxEVRange = 0;
					for (var i = 0, len = data.limits.length; i < len; i++) {
						if(data.limits[i].range > maxEVRange) {
							maxEVRange = data.limits[i].range;
						}
					}

					//Is the EV range or the trip distance larger?
					var max = (maxEVRange > data.roundTrip) ? maxEVRange : data.roundTrip;
					max+= 20;

					//Setup our scale
					var scale = d3.scaleLinear()
							.domain([0, max])
							.range([padding, width-padding]);

					var axis = d3.axisBottom()
								.scale(scale)
								.ticks(3)
								.tickFormat(function(d) { return d+unit; });

					// Append background rectangle
					svg.append('rect')
						.attr('height', height)
						.attr('y', y)
						.style('fill', '#333')
						.attr('x', function() {return scale(0);})
						.attr('width', function() {return scale(max)-padding+1;});

					// Append trip rectangle
					svg.append('rect')
						.attr('height', height)
						.attr('y', y)
						.style('fill', '#31a9d3')
						.attr('x', function() {return scale(0);})
						.attr('width', function() {return scale(0)-padding;})
						.transition()
							.duration(2000)
							.attr('width', function() { return scale(data.roundTrip)-padding;});

					// EV range limit bars
					svg.selectAll('line')
						.data(data.limits)
					.enter().append('line')
						.attr('y1', 6)
						.attr('y2', 26)
						.attr('x1', function(d) {return scale(d.range);})
						.attr('x2', function(d) {return scale(d.range);})
						.style('stroke', function(d) {
							// Red (out of range)
							if(d.range < data.roundTrip) {
								return '#bd2a2a';
							}
							// Orange (within 20 miles of being out of range)
							else if(d.range - 20 < data.roundTrip) {
								return '#e3b33f';
							}
							// Green
							else {
								return '#8dc63f';
							}
						})
						.style('stroke-width', '5px')
						.on('mouseover', function(d) {
							// Change text
							div.html(d.name+'\'s range');

							//Position the tooltip centered over the line
							var tooltipBox = div.node().getBoundingClientRect();
							var lineBox = d3.select(this).node().getBoundingClientRect();
							div.style('left', (lineBox.left - (tooltipBox.width / 2)) + 'px')
								.style('top', (lineBox.top - tooltipBox.height) + 'px');

							//Fade in
							div.transition()
								.duration(200)
								.style('opacity', 0.9);
						})
						.on('mouseout', function() {
							//Fade out
							div.transition()
								.duration(500)
								.style('opacity', 0);
						});

					// Append axis
					svg.append('g')
						.attr('class', 'range-scale')
						.attr('transform', 'translate(0, '+(y+height)+')')
						.call(axis);
				};
			});
		}
	}

})();
