(function() {
	'use strict';

	angular
		.module('results')
		.controller('Results', ResultsController);

	ResultsController.$inject = ['$rootScope', '$scope', '$timeout', 'Calcs', 'Settings', '$location', 'Converter'];

	function ResultsController($rootScope, $scope, $timeout, Calcs, Settings, $location, Converter) {

		var vm = this;
		var timer;

		vm.showResults = false;
		vm.resultsReady = false;
		vm.size = 'large';

		vm.shrink = shrink;
		vm.expand = expand;
		vm.helpDistance = helpDistance;
		vm.export = exporter;
		vm.showOptIn = showOptIn;
		vm.closePrompt = closePrompt;

		$rootScope.$on('enableResults', enableResults);
		$scope.$watch(getCars, redoCars, true);
		$scope.$watch(getRoute, redoRoute, true);
		$scope.$watch(getCommute, redoCommute, true);
		$scope.$watch(getPrices, redoPrices, true);
		$scope.$watch(getState, updateState, true);
		$scope.$watch(getCurrency, updateCurrency);

		$scope.$on('$destroy', function() {
			if(timer) {
				$timeout.cancel(timer);
			}
		});

		activate();

		////////////

		function activate() {
			vm.prompt = Settings.customizeBoxHidden();
		}

		function closePrompt() {
			vm.prompt = false;
			Settings.hideCustomizeBox();
		}

		function enableResults() {
			vm.showResults = true;
			updateResults();
		}

		function showOptIn() {
			$location.path('/optin');
		}

		function exporter() {
			$location.path('/export');
		}

		function shrink() {
			if(vm.size === 'large') {
				vm.size = 'med';
			}
			else if(vm.size === 'med') {
				vm.size = 'small';
			}
		}

		function expand() {
			if(vm.size === 'small') {
				vm.size = 'med';
			}
			else if(vm.size === 'med') {
				vm.size = 'large';
			}
		}

		function helpDistance() {
			$location.path('/instructions/distance');
		}

		function getCars() {
			return Settings.getCars();
		}

		function getRoute() {
			return Settings.getRoute();
		}

		function getCommute() {
			return Settings.getCommute();
		}

		function getPrices() {
			return Settings.getPrices();
		}

		function getState() {
			return Settings.getState();
		}

		function getCurrency() {
			return Settings.getCurrency();
		}

		function redoCars(newVal) {
			if(newVal && newVal.length) {
				Calcs.calcEVLimits();
				Calcs.calcCostData();
				updateResults();
			}
		}

		function redoRoute(newVal) {
			if(newVal) {
				Calcs.calcRoundTrip();
				Calcs.calcCostData();
				updateResults();
			}
		}

		function redoCommute(newVal) {
			if(newVal) {
				Calcs.calcCostData();
				updateResults();
			}
		}

		function redoPrices(newVal) {
			if(newVal) {
				vm.prices = newVal;
				Calcs.calcCostData();
				updateResults();
			}
		}

		function updateState(newVal) {
			if(newVal) {
				vm.state = newVal;
				redoPrices();
			}
		}

		function updateCurrency(newVal) {
			if(newVal) {
				vm.units = Settings.getUnits();
				vm.symbol = Converter.getCurrency(newVal).symbol;
				vm.prices = Settings.getPrices();
				Calcs.calcCostData();
				updateResults();
			}
		}

		function updateResults() {

			if(Calcs.ready() && vm.showResults) {
				$rootScope.$emit('resultsReady');

				vm.resultsReady = true;

				// $timeout is needed here to give the results div a chance to show
				// before populating the data
				timer = $timeout(function() {
					vm.rangeData = {
						roundTrip: parseInt(Calcs.getRoundTrip().toFixed(0)),
						limits: Calcs.getEVLimits(),
					};
					vm.carTotals = Calcs.getCarTotals();
				});
			}
		}
	}

})();
