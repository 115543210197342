(function() {
	'use strict';

	angular
		.module('sidebar')
		.controller('Sidebar', SideBarController);

	SideBarController.$inject = ['$rootScope', '$scope', '$location', 'Settings'];

	function SideBarController($rootScope, $scope, $location, Settings) {

		var vm = this;

		vm.step = 1;
		vm.helpHome = helpHome;
		vm.helpCommute = helpCommute;

		$rootScope.$on('$routeChangeSuccess', updateStep);
		$scope.$watch(getOrigin, setOriginAddress);
		$scope.$watch(getDest, setDestAddress);

		////////////

		function helpHome() {
			$location.path('/instructions/home');
		}
		function helpCommute() {
			$location.path('/instructions/commute');
		}

		function updateStep() {
			var path = $location.path();

			if(path === '/locations/start') {
				vm.step = 1;
			}
			else if(path === '/locations/end') {
				vm.step = 2;
			}
			else if(path === '/') {
				if(getOrigin() && getDest()) {
					vm.step = 0;
				}
			}
		}

		function setOriginAddress(newVal) {
			if(newVal) {
				vm.startAddress = newVal.formatted_address;
			}
		}

		function setDestAddress(newVal) {
			if(newVal) {
				vm.endAddress = newVal.formatted_address;
			}
		}

		function getOrigin() {
			return Settings.getOrigin();
		}

		function getDest() {
			return Settings.getDest();
		}
	}

})();
