(function() {
	'use strict';

	angular
		.module('commute')
		.config(Config);

	Config.$inject = ['$routeProvider'];

	function Config($routeProvider) {
		$routeProvider
			.when('/commute', {
				templateUrl: './modules/commute/views/commute.client.view.html',
				controller: 'Commute',
				controllerAs: 'vm'
			});
	}

})();