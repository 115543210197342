(function() {
	'use strict';

	angular
		.module('optin')
		.config(Config);

	Config.$inject = ['$routeProvider'];

	function Config($routeProvider) {
		$routeProvider
			.when('/optin', {
				templateUrl: './modules/optin/views/optin.client.view.html',
				controller: 'OptIn',
				controllerAs: 'vm'
			});
	}

})();