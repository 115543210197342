(function() {
	'use strict';

	angular
		.module('prices')
		.factory('Converter', Converter);

	Converter.$inject = ['$q', '$http'];

	function Converter($q, $http) {

		var currency = null;
		var rates = null;
		var lPerGal = 3.78541;

		var currencies = [
			{code: 'AUD', name: 'Austrailian Dollar', symbol: '$', unit: 'L'},
			{code: 'BGN', name: 'Bulgarian Lev', symbol: 'лв', unit: 'L'},
			{code: 'BRL', name: 'Brazilian Real', symbol: 'R$', unit: 'L'},
			{code: 'CAD', name: 'Canadian Dollar', symbol: '$', unit: 'L'},
			{code: 'CHF', name: 'Swiss Franc', symbol: 'CHF', unit: 'L'},
			{code: 'CNY', name: 'Chinese Yuan', symbol: '¥', unit: 'L'},
			{code: 'CZK', name: 'Czech Republic Koruna', symbol: 'Kč', unit: 'L'},
			{code: 'DKK', name: 'Danish Krone', symbol: 'kr', unit: 'L'},
			{code: 'EUR', name: 'Euro', symbol: '€', unit: 'L'},
			{code: 'GBP', name: 'British Pound', symbol: '£', unit: 'L'},
			{code: 'HKD', name: 'Hong Kong Dollar', symbol: '$', unit: 'L'},
			{code: 'HRK', name: 'Croatian Kuna', symbol: 'kn', unit: 'L'},
			{code: 'HUF', name: 'Hungarian Forint', symbol: 'Ft', unit: 'L'},
			{code: 'IDR', name: 'Indonesian Rupiah', symbol: 'Rp', unit: 'L'},
			{code: 'ILS', name: 'Israeli New Sheqel', symbol: '₪', unit: 'L'},
			{code: 'INR', name: 'Indian Rupee', symbol: '₹', unit: 'L'},
			{code: 'JPY', name: 'Japanese Yen', symbol: '¥', unit: 'L'},
			{code: 'KRW', name: 'South Korean Won', symbol: '₩', unit: 'L'},
			{code: 'MXN', name: 'Mexican Peso', symbol: '$', unit: 'L'},
			{code: 'MYR', name: 'Malaysian Ringgit', symbol: 'RM', unit: 'L'},
			{code: 'NOK', name: 'Norwegian Krone', symbol: 'kr', unit: 'L'},
			{code: 'NZD', name: 'New Zealand Dollar', symbol: '$', unit: 'L'},
			{code: 'PHP', name: 'Philippine Peso', symbol: '₱', unit: 'L'},
			{code: 'PLN', name: 'Polish Zloty', symbol: 'zł', unit: 'L'},
			{code: 'RON', name: 'Romanian Leu', symbol: 'lei', unit: 'L'},
			{code: 'RUB', name: 'Russian Ruble', symbol: '₽', unit: 'L'},
			{code: 'SEK', name: 'Swedish Krona', symbol: 'kr', unit: 'L'},
			{code: 'SGD', name: 'Singapore Dollar', symbol: '$', unit: 'L'},
			{code: 'THB', name: 'Thai Baht', symbol: '฿', unit: 'L'},
			{code: 'TRY', name: 'Turkish Lira', symbol: '₺', unit: 'L'},
			{code: 'USD', name: 'US Dollar', symbol: '$', unit: 'gal'},
			{code: 'ZAR', name: 'South African Rand', symbol: 'R', unit: 'L'},
		];

		var factory = {
			getCurrencies: getCurrencies,
			setCurrency: setCurrency,
			getCurrency: getCurrency,
			initRates: initRates,
			convert: convert,
			isValid: isValid,
		};

		return factory;

		////////////

		function isValid(currency) {
			for(var i = 0; i < currencies.length; i++) {
				if (currencies[i].code === currency) {
					return true;
				}
			}

			return false;
		}

		function initRates() {

			var deferred = $q.defer();

			if(rates) {
				deferred.resolve();
			}
			else {
				$http.get('./api/prices/rates').then(function(res) {
					rates = res.data.rates;
					deferred.resolve();
				}, function(err) {
					deferred.reject(err);
				});
			}

			return deferred.promise;
		}

		function convert(val, code, units) {

			units = typeof(units) === 'undefined' ? true : units;

			if(currency === code) {
				//console.log('No conversion necessary');
				return val;
			}
			else {

				//console.log('Converting '+val+' from '+currency+' to '+code);

				var rate;

				if(currency === 'USD') {
					rate = rates[code];
				}
				else if(code === 'USD') {
					rate = 1/rates[currency];
				}
				else {
					rate = rates[code]/rates[currency];
				}

				//console.log(rate);

				var curFrom = getCurrency();
				var curTo = getCurrency(code);
				var newVal;

				if(units) {
					if(curFrom.unit === 'L' && curTo.unit === 'gal') {
						//console.log('Converting L -> gal');
						newVal = val*rate*lPerGal;
					}
					else if(curFrom.unit === 'gal' && curTo.unit === 'L') {
						//console.log('Converting gal -> L');
						newVal = val*rate*(1/lPerGal);
					}
					else {
						//console.log('No unit conversion necessary');
						newVal = val*rate;
					}
				}
				else {
					//console.log('Skipping unit conversion');
					//console.log(rate);
					newVal = val*rate;
				}

				//console.log('Converted value before rounding: '+newVal);
				return parseFloat(newVal.toFixed(2));
			}
		}

		function getCurrencies() {
			return currencies;
		}

		function setCurrency(code) {
			currency = code;
		}

		function getCurrency(code) {

			code = typeof(code) === 'undefined' ? currency : code;

			for(var i = 0, len = currencies.length; i < len; i++) {
				if(currencies[i].code === code) {
					return {
						code: currencies[i].code,
						symbol: currencies[i].symbol,
						unit: currencies[i].unit,
					};
				}
			}
		}

	}

})();
