(function() {
	'use strict';

	angular
		.module('locations')
		.factory('Geocoder', Geocoder);

	Geocoder.$inject = ['$q', 'uiGmapGoogleMapApi'];

	function Geocoder($q, uiGmapGoogleMapApi) {

		var googleMaps;
		var geocoder;

		uiGmapGoogleMapApi.then(function(maps) {
			googleMaps = maps;
			geocoder = new googleMaps.Geocoder();
    	});

		var factory = {
			reverse: reverse
		};

		return factory;

		////////////

    	function reverse(lat, lng) {

    		var deferred = $q.defer();

    		geocoder.geocode({
    			location: {
    				lat: lat,
    				lng: lng
    			}
    		}, function(results, status) {
    			if(status === googleMaps.GeocoderStatus.OK) {
    				deferred.resolve(results[0]);
    			}
    			else {
    				deferred.reject();
    			}
    		});

    		return deferred.promise;
    	}

	}

})();
