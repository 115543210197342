(function() {
	'use strict';

	angular
		.module('core')
		.controller('Core', CoreController);

	CoreController.$inject = ['$rootScope', '$scope', '$location'];

	function CoreController($rootScope, $scope, $location) {

		var vm = this;

		vm.loading = true;
		vm.resultsReady = false;
		vm.resultsHidden = false;

		$rootScope.$on('loaded', hideLoading);
		$rootScope.$on('resultsReady', resultsReady);
		$rootScope.$on('hideResults', hideResults);
		$rootScope.$on('showResults', showResults);
		$rootScope.$on('$locationChangeSuccess', updateStep);

		////////////

		function hideLoading() {
			vm.loading = false;
			$scope.$apply();
		}

		function resultsReady() {
			vm.resultsReady = true;
		}

		function hideResults() {
			vm.resultsHidden = true;
		}

		function showResults() {
			vm.resultsHidden = false;
		}

		function updateStep() {
			var currPath = $location.path();

			vm.step1 = false;
			vm.step2 = false;

			if(currPath === '/locations/start') {
				vm.step1 = true;
			}
			else if(currPath === '/locations/end') {
				vm.step2 = true;
			}
		}
	}

})();
