(function() {
	'use strict';

	angular
		.module('core')
		.factory('Settings', Settings);

	Settings.$inject = [];

	function Settings() {

		var customizeBoxVisible = true;

		var location;
		var state;
		var currency = 'USD';
		var imperialUnits = {
			economy: 'MPG',
			economy2: 'MPGe',
			distance: 'miles',
			distanceShort: 'mi',

		};
		var siUnits = {
			economy: 'L/100km',
			economy2: 'L/100km',
			distance: 'kilometers',
			distanceShort: 'km',
		};
		var units = imperialUnits;

		var prices;

		var commuteSettings = {
			frequency: 5,
			period: 'week',
			homeCharger: true,
			destCharger: false,
			destType: 'lvl2',
			destHours: 0,
			destPrice: 0,
			destPer: 'hour'
		};

		// MY 2023 ICEs
		var cargroup1 = [
			45099,
			45101,
			45102,
			45103,
			45104,
			45105,
			45106,
			45107,
			45108,
			45109,
			45157,
			45158,
			45180,
			45181,
			45182,
			45183,
			45184,
			45185,
			45186,
			45187,
			45188,
			45189,
			45202,
			45203,
			45204,
			45205,
			45206,
			45207,
			45208,
			45209,
			45210,
			45221,
			45222,
			45223,
			45224,
			45225,
			45226,
			45227,
			45228,
			45229,
			45230,
			45231,
			45232,
			45233,
			45234,
			45235,
			45236,
			45237,
			45238,
			45239,
			45240,
			45241,
			45242,
			45243,
			45244,
			45245,
			45246,
			45247,
			45248,
			45249,
			45250,
			45251,
			45252,
			45253,
			45260,
			45261,
			45262,
			45263,
			45264,
			45265,
			45266,
			45267,
			45268,
			45269,
			45270,
			45271,
			45272,
			45273,
			45274,
			45275,
			45276,
			45277,
			45278,
			45279,
			45280,
			45281,
			45282,
			45283,
			45288,
			45289,
			45293,
			45294,
			45295,
			45296,
			45297,
			45298,
			45299,
			45300,
			45301,
			45302,
			45303,
			45304,
			45305,
			45306,
			45307,
			45308,
			45309,
			45310,
			45311,
			45312,
			45313,
			45314,
			45319,
			45320,
			45321,
			45323,
			45324,
			45325,
			45333,
			45334,
			45335,
			45336,
			45337,
			45338,
			45339,
			45340,
			45341,
			45342,
			45343,
			45344,
			45345,
			45346,
			45347,
			45348,
			45349,
			45350,
			45351,
			45352,
			45353,
			45354,
			45355,
			45356,
			45357,
			45358,
			45359,
			45360,
			45361,
			45362,
			45363,
			45364,
			45365,
			45366,
			45367,
			45368,
			45369,
			45370,
			45371,
			45372,
			45373,
			45374,
			45375,
			45376,
			45377,
			45378,
			45379,
			45380,
			45381,
			45382,
			45383,
			45384,
			45385,
			45386,
			45387,
			45388,
			45389,
			45390,
			45391,
			45392,
			45393,
			45394,
			45399,
			45400,
			45401,
			45402,
			45403,
			45404,
			45405,
			45406,
			45407,
			45408,
			45409,
			45410,
			45411,
			45412,
			45414,
			45415,
			45416,
			45417,
			45418,
			45419,
			45420,
			45421,
			45422,
			45423,
			45424,
			45425,
			45426,
			45427,
			45428,
			45429,
			45430,
			45431,
			45432,
			45433,
			45434,
			45435,
			45436,
			45437,
			45438,
			45439,
			45440,
			45441,
			45442,
			45443,
			45444,
			45445,
			45446,
			45447,
			45448,
			45449,
			45450,
			45451,
			45452,
			45453,
			45454,
			45455,
			45456,
			45457,
			45458,
			45459,
			45460,
			45461,
			45462,
			45463,
			45464,
			45465,
			45466,
			45467,
			45468,
			45469,
			45470,
			45471,
			45472,
			45473,
			45474,
			45475,
			45476,
			45477,
			45478,
			45479,
			45480,
			45481,
			45482,
			45483,
			45484,
			45485,
			45486,
			45487,
			45488,
			45489,
			45490,
			45491,
			45492,
			45493,
			45494,
			45495,
			45496,
			45497,
			45498,
			45499,
			45500,
			45501,
			45502,
			45503,
			45504,
			45505,
			45506,
			45507,
			45508,
			45509,
			45510,
			45511,
			45512,
			45513,
			45514,
			45515,
			45516,
			45517,
			45518,
			45519,
			45520,
			45521,
			45522,
			45523,
			45524,
			45525,
			45526,
			45527,
			45528,
			45529,
			45530,
			45531,
			45532,
			45533,
			45534,
			45535,
			45536,
			45537,
			45538,
			45539,
			45540,
			45541,
			45542,
			45543,
			45544,
			45545,
			45546,
			45547,
			45548,
			45549,
			45550,
			45551,
			45552,
			45553,
			45554,
			45555,
			45556,
			45557,
			45558,
			45559,
			45560,
			45561,
			45562,
			45563,
			45564,
			45565,
			45566,
			45567,
			45568,
			45569,
			45570,
			45571,
			45572,
			45573,
			45574,
			45575,
			45576,
			45577,
			45578,
			45579,
			45580,
			45581,
			45582,
			45583,
			45584,
			45585,
			45586,
			45587,
			45588,
			45589,
			45590,
			45600,
			45601,
			45602,
			45603,
			45604,
			45605,
			45606,
			45607,
			45608,
			45609,
			45610,
			45611,
			45612,
			45613,
			45614,
			45615,
			45616,
			45617,
			45618,
			45619,
			45620,
			45621,
			45622,
			45623,
			45624,
			45625,
			45626,
			45627,
			45628,
			45629,
			45630,
			45631,
			45632,
			45633,
			45634,
			45635,
			45636,
			45637,
			45638,
			45639,
			45640,
			45641,
			45642,
			45643,
			45644,
			45645,
			45646,
			45647,
			45648,
			45649,
			45650,
			45651,
			45652,
			45653,
			45654,
			45655,
			45656,
			45657,
			45658,
			45659,
			45660,
			45661,
			45662,
			45663,
			45664,
			45665,
			45666,
			45667,
			45668,
			45669,
			45670,
			45671,
			45672,
			45673,
			45674,
			45675,
			45676,
			45677,
			45678,
			45679,
			45680,
			45681,
			45682,
			45683,
			45684,
			45685,
			45686,
			45687,
			45688,
			45689,
			45690,
			45691,
			45692,
			45693,
			45694,
			45695,
			45696,
			45697,
			45698,
			45699,
			45700,
			45701,
			45702,
			45703,
			45704,
			45705,
			45706,
			45707,
			45708,
			45709,
			45710,
			45711,
			45712,
			45713,
			45714,
			45717,
			45718,
			45719,
			45720,
			45721,
			45722,
			45723,
			45724,
			45725,
			45726,
			45727,
			45728,
			45729,
			45730,
			45731,
			45732,
			45733,
			45734,
			45735,
			45736,
			45737,
			45738,
			45739,
			45740,
			45741,
			45742,
			45743,
			45744,
			45745,
			45746,
			45747,
			45748,
			45762,
			45763,
			45764,
			45765,
			45766,
			45767,
			45768,
			45769,
			45770,
			45771,
			45772,
			45773,
			45774,
			45775,
			45776,
			45777,
			45778,
			45779,
			45780,
			45781,
			45782,
			45783,
			45784,
			45785,
			45786,
			45787,
			45788,
			45789,
			45790,
			45791,
			45792,
			45793,
			45794,
			45795,
			45796,
			45797,
			45798,
			45799,
			45800,
			45801,
			45802,
			45803,
			45804,
			45805,
			45806,
			45807,
			45808,
			45809,
			45810,
			45811,
			45812,
			45813,
			45814,
			45815,
			45816,
			45817,
			45818,
			45819,
			45820,
			45821,
			45822,
			45823,
			45824,
			45825,
			45826,
			45827,
			45828,
			45829,
			45830,
			45831,
			45832,
			45833,
			45834,
			45835,
			45836,
			45837,
			45838,
			45839,
			45840,
			45841,
			45842,
			45843,
			45844,
			45845,
			45846,
			45847,
			45848,
			45849,
			45850,
			45851,
			45852,
			45853,
			45854,
			45855,
			45856,
			45857,
			45858,
			45859,
			45860,
			45861,
			45862,
			45863,
			45864,
			45865,
			45866,
			45867,
			45868,
			45869,
			45870,
			45871,
			45872,
			45873,
			45874,
			45875,
			45876,
			45877,
			45878,
			45879,
			45880,
			45881,
			45882,
			45883,
			45884,
			45885,
			45886,
			45887,
			45888,
			45889,
			45890,
			45891,
			45892,
			45893,
			45894,
			45895,
			45896,
			45897,
			45898,
			45899,
			45900,
			45901,
			45902,
			45903,
			45904,
			45905,
			45906,
			45907,
			45908,
			45909,
			45910,
			45911,
			45912,
			45913,
			45914,
			45915,
			45916,
			45917,
			45918,
			45919,
			45920,
			45921,
			45922,
			45923,
			45924,
			45925,
			45926,
			45927,
			45928,
			45929,
			45930,
			45931,
			45932,
			45933,
			45934,
			45935,
			45936,
			45937,
			45938,
			45939,
			45940,
			45941,
			45942,
			45943,
			45944,
			45945,
			45946,
			45947,
			45948,
			45949,
			45950,
			45951,
			45952,
			45953,
			45954,
			45955,
			45956,
			45957,
			45958,
			45959,
			45960,
			45961,
			45962,
			45963,
			45964,
			45965,
			45966,
			45967,
			45968,
			45969,
			45970,
			45971,
			45972,
			45973,
			45974,
			45975,
			45976,
			45977,
			45978,
			45979,
			45980,
			46038,
			46039,
			46040,
			46041,
			46042,
			46043,
			46044,
			46045,
			46046,
			46047,
			46048,
			46049,
			46050,
			46051,
			46052,
			46053,
			46054,
			46055,
			46056,
			46057,
			46058,
			46059,
			46060,
			46061,
			46062,
			46063,
			46064,
			46065,
			46066,
			46067,
			46068,
			46069,
			46070,
			46071,
			46072,
			46073,
			46074,
			46075,
			46076,
			46077,
			46078,
			46079,
			46080,
			46081,
			46082,
			46083,
			46084,
			46085,
			46086,
			46087,
			46088,
			46089,
			46090,
			46091,
			46092,
			46093,
			46094,
			46095,
			46096,
			46097,
			46098,
			46099,
			46100,
			46101,
			46102,
			46103,
			46104,
			46105,
			46106,
			46107,
			46108,
			46109,
			46110,
			46111,
			46112,
			46113,
			46114,
			46115,
			46116,
			46117,
			46118,
			46119,
			46120,
			46121,
			46122,
			46123,
			46124,
			46125,
			46126,
			46127,
			46128,
			46129,
			46130,
			46131,
			46132,
			46133,
			46134,
			46135,
			46136,
			46137,
			46138,
			46139,
			46140,
			46141,
			46142,
			46143,
			46144,
			46145,
			46146,
			46147,
			46148,
			46149,
			46150,
			46151,
			46152,
			46153,
			46154,
			46155,
			46156,
			46157,
			46158,
			46159,
			46160,
			46161,
			46162,
			46163,
			46164,
			46165,
			46166,
			46167,
			46168,
			46169,
			46170,
			46171,
			46172,
			46173,
			46174,
			46175,
			46176,
			46177,
			46178,
			46179,
			46180,
			46181,
			46182,
			46183,
			46184,
			46185,
			46186,
			46187,
			46188,
			46189,
			46190,
			46191,
			46192,
			46193,
			46194,
			46195,
			46196,
			46197,
			46198,
			46199,
			46200,
			46201,
			46202,
			46203,
			46214,
			46215,
			46216,
			46217,
			46218,
			46219,
			46220,
			46221,
			46222,
			46223,
			46224,
			46225,
			46226,
			46227,
			46228,
			46229,
			46230,
			46231,
			46232,
			46233,
			46234,
			46235,
			46236,
			46237,
			46238,
			46239,
			46240,
			46241,
			46242,
			46243,
			46244,
			46263,
			46264,
			46265,
			46266,
			46267,
			46268,
			46269,
			46270,
			46271,
			46272,
			46273,
			46274,
			46275,
			46276,
			46277,
			46278,
			46279,
			46280,
			46281,
			46282,
			46283,
			46284,
			46285,
			46286,
			46287,
			46288,
			46289,
			46290,
			46291,
			46292,
			46293,
			46294,
			46295,
			46296,
			46297,
			46298,
			46299,
			46300,
			46301,
			46302,
			46318,
			46319,
			46320,
			46321,
			46322,
			46323,
			46324,
			46325,
			46326,
			46333,
			46334,
			46335,
			46336,
			46337,
			46338,
			46339,
			46340,
			46341,
			46342,
			46343,
			46344,
			46345,
			46346,
			46347,
			46348,
			46349,
			46350,
			46351,
			46352,
			46353,
			46354,
			46355,
			46356,
			46357,
			46358,
			46359,
			46360,
			46361,
			46362,
			46363,
			46364,
			46365,
			46366,
			46367,
			46368,
			46369,
			46370,
			46371,
			46372,
			46373,
			46374,
			46375,
			46376,
			46377,
			46378,
			46379,
			46380,
			46381,
			46382,
			46383,
			46384,
			46385,
			46386,
			46387,
			46388,
			46389,
			46390,
			46391,
			46392,
			46393,
			46394,
			46395,
			46396,
			46397,
			46398,
			46399,
			46400,
			46401,
			46402,
			46403,
			46404,
			46405,
			46406,
			46407,
			46408,
			46409,
			46410,
			46411,
			46412,
			46413,
			46414,
			46415,
			46416,
			46417,
			46418,
			46419,
			46420,
			46421,
			46422,
			46423,
			46424,
			46425,
			46426,
			46427,
			46428,
			46429,
			46430,
			46431,
			46432,
			46433,
			46434,
			46435,
			46436,
			46437,
			46438,
			46439,
			46440,
			46441,
			46442,
			46443,
			46457,
			46458,
			46459,
			46460,
			46506,
			46507,
			46508,
			46509,
			46510,
			46511,
			46553,
			46554,
			46555,
			46556,
			46557,
			46558,
			46559,
			46560,
			46561,
			46562,
			46563,
			46564,
			46565,
			46566,
			47506,
			47507,
			47508,
			47509,
			47510,
			47511,
			47512,
			47513,
			47514,
			47515,
			47516,
			47517,
			47518,
			47519,
			47520,
			47521,
		];

		// MY 2023 PHEVs
		var cargroup2 = [
			45331,
			45395,
			46245,
			46246,
			46247,
			46248,
			46249,
			46250,
			46251,
			46252,
			46253,
			46254,
			46255,
			46256,
			46257,
			46258,
			46259,
			46260,
			46261,
			46262,
			46627,
			46628,
			46629,
			46630,
			47213,
			47214,
			47215,
			47216,
			47217,
			47218,
			47219,
			47220,
			47221,
			47222,
			47223,
			47224,
			47225,
			47226,
			47227,
			47228,
			47229,
			47230,
			47279,
			47280,
		];

		// MY 2023 non-Tesla BEVs
		var cargroup3 = [
			45326,
			45327,
			45328,
			45329,
			45330,
			45749,
			45750,
			45751,
			45752,
			45753,
			45754,
			45755,
			45756,
			45757,
			45758,
			45759,
			45760,
			45761,
			45981,
			45982,
			45983,
			45984,
			45985,
			45986,
			45987,
			45988,
			45989,
			45990,
			45991,
			45992,
			45993,
			45994,
			45995,
			45996,
			45997,
			45998,
			45999,
			46000,
			46001,
			46002,
			46003,
			46004,
			46005,
			46006,
			46007,
			46008,
			46009,
			46010,
			46011,
			46012,
			46013,
			46014,
			46015,
			46016,
			46017,
			46018,
			46019,
			46020,
			46021,
			46022,
			46023,
			46024,
			46025,
			46026,
			46027,
			46028,
			46029,
			46030,
			46031,
			46032,
			46033,
			46034,
			46035,
			46036,
			46037,
			46303,
			46304,
			46305,
			46306,
			46307,
			46308,
			46309,
			46310,
			46311,
			46312,
			46313,
			46314,
			46315,
			46316,
			46317,
			46327,
			46328,
			46329,
			46512,
			46513,
			46514,
			46515,
			46516,
			46517,
			46518,
			46519,
			46614,
			46615,
			46616,
			46617,
			46618,
			46619,
			46620,
			46621,
			46622,
			46623,
			46624,
			46984,
			46985,
			46986,
			46987,
			46988,
			46989,
			46990,
			46991,
			46992,
			46993,
			46994,
			46995,
			46996,
			46997,
			46998,
			46999,
			47000,
			47001,
			47002,
			47003,
			47004,
			47005,
		];

		// MY 2023 Tesla BEVs
		var cargroup4 = [
			46204,
			46205,
			46206,
			46207,
			46208,
			46209,
			46210,
			46211,
			46212,
			46213,
		];

		var defaultCars = [
			randomCar(cargroup1),
			randomCar(cargroup2),
			randomCar(cargroup3),
			randomCar(cargroup4)
		];

		var cars = [];

		var origin, dest, route, bounds;

		var defaultZoom = 8;

		var factory = {
			getBounds:      getBounds,
			setBounds:      setBounds,
			getPrices: 		getPrices,
			setPrices: 		setPrices,
			getState: 		getState,
			setState: 		setState,
			setLocation: 	setLocation,
			getLocation: 	getLocation,
			setOrigin: 		setOrigin,
			getOrigin: 		getOrigin,
			setDest: 		setDest,
			getDest: 		getDest,
			setRoute: 		setRoute,
			getRoute: 		getRoute,
			setCommute: 	setCommute,
			getCommute: 	getCommute,
			getCar: 		getCar,
			updateCar: 		updateCar,
			setCar: 		setCar,
			getCars: 		getCars,
			getDefaultCars: getDefaultCars,
			hideCustomizeBox: hideCustomizeBox,
			customizeBoxHidden: customizeBoxHidden,
			getCurrency: getCurrency,
			setCurrency: setCurrency,
			getUnits: getUnits,
			getDefaultZoom: getDefaultZoom,
			setDefaultZoom: setDefaultZoom,
		};

		return factory;

		////////////

		function getUnits() {
			return units;
		}

		function getCurrency() {
			return currency;
		}

		function setCurrency(newVal) {
			var i, len;
			if(currency === 'USD' && newVal !== 'USD') {
				for(i = 0, len = cars.length; i < len; i++) {
					//mi -> km
					cars[i].elecRange*= 1.60934;
					//kwh/mi -> kwh/km
					cars[i].elecEconomy*= 0.621371;
					cars[i].cdEconomy*= 0.621371;
					//gal / mile -> L/km
					cars[i].fuelEconomy*= 2.35215;
					cars[i].fuelEconomy2*= 2.35215;
				}
			}
			else if(newVal === 'USD' && currency !== 'USD') {
				for(i = 0, len = cars.length; i < len; i++) {
					//mi <- km
					cars[i].elecRange*= 0.621371;
					//kwh/mi <- kwh/km
					cars[i].elecEconomy*= 1.60934;
					cars[i].cdEconomy*= 1.60934;
					//gal / mile <- L/km
					cars[i].fuelEconomy*= 0.425144;
					cars[i].fuelEconomy2*= 0.425144;
				}
			}

			// Update Units
			if(newVal === 'USD') {
				units = imperialUnits;
			}
			else {
				units = siUnits;
			}

			currency = newVal;
		}

		function hideCustomizeBox() {
			customizeBoxVisible = false;
		}

		function customizeBoxHidden() {
			return customizeBoxVisible;
		}

		function getBounds() {
			return bounds;
		}

		function setBounds(newBounds) {
			bounds = {
				east: newBounds.getNorthEast().lng(),
				north: newBounds.getNorthEast().lat(),
				south: newBounds.getSouthWest().lat(),
				west: newBounds.getSouthWest().lng(),
			};
		}

		function getPrices() {
			if(prices) {
				return {
					regular: 		prices.regular,
					super: 			prices.super,
					premium: 		prices.premium,
					diesel: 		prices.diesel,
					electricity: 	prices.electricity,
				};
			}
		}

		function setPrices(newPrices) {
			prices = {
				regular: 		newPrices.regular,
				super: 			newPrices.super,
				premium: 		newPrices.premium,
				diesel: 		newPrices.diesel,
				electricity: 	newPrices.electricity,
			};
		}

		function getState() {
			return state;
		}

		function setState(newState) {
			state = newState;
		}

		function setLocation(newLocation) {
			location = newLocation;

			// use national average if not US
			// or if the state is blank
			if(location.country_name === 'United States' &&
				location.region_code !== '') {
				state = location.region_code;
			}
			else {
				state = 'US';
			}
		}

		function getLocation() {
			return {
				latitude: location.latitude,
				longitude: location.longitude
			};
		}

		function setOrigin(newOrigin) {
			origin = newOrigin;
		}

		function getOrigin() {
			return origin;
		}

		function setDest(newDest) {
			dest = newDest;
		}

		function getDest() {
			return dest;
		}

		function setRoute(newRoute) {
			route = newRoute;
		}

		function getRoute() {
			return route;
		}

		function setCommute(newCommute) {
			commuteSettings = {
				frequency: 		newCommute.frequency,
				period: 		newCommute.period,
				homeCharger: 	newCommute.homeCharger,
				destCharger: 	newCommute.destCharger,
				destType: 		newCommute.destType,
				destHours: 		newCommute.destHours,
				destPrice: 		newCommute.destPrice,
				destPer: 		newCommute.destPer
			};
		}

		function getCommute() {
			return {
				frequency: 		commuteSettings.frequency,
				period: 		commuteSettings.period,
				homeCharger: 	commuteSettings.homeCharger,
				destCharger: 	commuteSettings.destCharger,
				destType: 		commuteSettings.destType,
				destHours: 		commuteSettings.destHours,
				destPrice: 		commuteSettings.destPrice,
				destPer: 		commuteSettings.destPer
			};
		}

		function getCar(idx) {
			if(currency === 'USD') {
				return {
					id: cars[idx].id,
					year: cars[idx].year,
					make: cars[idx].make,
					model: cars[idx].model,
					class: cars[idx].class,
					type: cars[idx].type,
					evMotor: cars[idx].evMotor,
					fuelType: cars[idx].fuelType,
					// mi
					elecRange: Math.round(cars[idx].elecRange),
					// kwh / 100 mi <- kwh / mi
					elecEconomy: Math.round(cars[idx].elecEconomy * 100),
					// gal / 100mi <- gal / mi
					cdEconomy: Math.round(cars[idx].cdEconomy * 100),
					// mpg <- gal / mi
					fuelEconomy: cars[idx].fuelEconomy > 0 ? Math.round(1 / cars[idx].fuelEconomy) : 0,
					fuelEconomy2: cars[idx].fuelEconomy2 > 0 ? Math.round(1 / cars[idx].fuelEconomy2) : 0,
					// hrs
					lvl2chargeTime: cars[idx].lvl2chargeTime,
				};
			}
			else {
				return {
					id: cars[idx].id,
					year: cars[idx].year,
					make: cars[idx].make,
					model: cars[idx].model,
					class: cars[idx].class,
					type: cars[idx].type,
					evMotor: cars[idx].evMotor,
					fuelType: cars[idx].fuelType,
					// km
					elecRange: Math.round(cars[idx].elecRange),
					// L / 100km <- L / km
					elecEconomy: Math.round(cars[idx].elecEconomy * 100),
					// L / 100km <- L / km
					cdEconomy: Math.round(cars[idx].cdEconomy * 100),
					// L / 100km <- L / km
					fuelEconomy: Math.round(cars[idx].fuelEconomy * 100),
					fuelEconomy2: Math.round(cars[idx].fuelEconomy2 * 100),
					// hrs
					lvl2chargeTime: cars[idx].lvl2chargeTime,
				};
			}
		}

		function updateCar(idx, updated) {
			if(currency === 'USD' && updated.fuelEconomy !== Math.round(1 / cars[idx].fuelEconomy)) {
				// mpg -> gal / mile
				cars[idx].fuelEconomy = 1 / updated.fuelEconomy;
			}
			else if(currency !== 'USD' && updated.fuelEconomy !== Math.round(cars[idx].fuelEconomy*100)) {
				// L / 100km -> L / km
				cars[idx].fuelEconomy = updated.fuelEconomy / 100;
			}

			if(updated.elecRange !== Math.round(cars[idx].elecRange)) {
				cars[idx].elecRange = updated.elecRange;
			}
			// Not rounded anyways
			cars[idx].lvl2chargeTime = updated.lvl2chargeTime;
		}

		function setCar(idx, newCar) {

			// fallback on rounded numbers of unrounded is not available
			var combo_fueltype1 = newCar.comb08U > 0 ? newCar.comb08U : newCar.comb08;
			var combo_fueltype2 = newCar.combA08U > 0 ? newCar.combA08U : newCar.combA08;

			cars[idx] = {
				id: newCar.id,
				year: newCar.year,
				make: newCar.make,
				model: newCar.model,
				class: newCar.VClass,
				type: newCar.atvType,
				evMotor: newCar.evMotor,
				fuelType: newCar.fuelType1,
				// mi
				elecRange: newCar.atvType ? newCar.range || newCar.rangeA : 0,
				// kwh / 100 mi -> kwh / mi
				elecEconomy: newCar.atvType ? newCar.combE / 100 : 0,
				// gal / 100mi -> gal / mi
				cdEconomy: newCar.atvType ? newCar.combinedCD / 100 : 0,
				// mpg -> gal / mi
				fuelEconomy: combo_fueltype1 > 0 ? 1 / combo_fueltype1 : 0,
				fuelEconomy2: combo_fueltype2 > 0 ? 1 / combo_fueltype2 : 0,
				// hrs
				lvl2chargeTime: newCar.charge240,
			};
		}

		function getCars() {
			return cars;
		}

		function randomCar(arr) {
			return arr[Math.floor(Math.random() * arr.length)];
		}

		function getDefaultCars() {
			return defaultCars;
		}

		function getDefaultZoom() {
			return defaultZoom;
		}

		function setDefaultZoom(zoom) {
			defaultZoom = zoom;
		}
	}

})();
