(function() {
	'use strict';

	angular
		.module('commute')
		.controller('Commute', CommuteController);

	CommuteController.$inject = ['$scope', '$location', 'Settings', 'Converter'];

	function CommuteController($scope, $location, Settings, Converter) {

		var vm = this;

		vm.cancel = cancel;
		vm.update = update;
		vm.changePeriod = changePeriod;
		vm.closePopup = closePopup;

		$scope.$watch(getCurrency, updateCurrency);

		activate();

		////////////

		function activate() {
			vm.symbol = Converter.getCurrency(Settings.getCurrency()).symbol;
			vm.currentSettings = Settings.getCommute();
			reset();
			changePeriod();
		}

		function updateCurrency(newVal) {
			if(newVal) {
				vm.symbol = Converter.getCurrency(newVal).symbol;
				vm.currentSettings.destPrice = Settings.getCommute().destPrice;
				vm.destPrice = vm.currentSettings.destPrice;
			}
		}

		function getCurrency() {
			return Settings.getCurrency();
		}

		function closePopup() {
			$location.path('/');
		}

		function update() {
			var newSettings = {
				frequency: vm.frequency,
				period: vm.period,
				homeCharger: vm.homeCharger,
				destCharger: vm.destCharger,
				destType: vm.destType,
				destHours: vm.destHours,
				destPrice: vm.destPrice,
				destPer: vm.destPer
			};
			Settings.setCommute(newSettings);
			vm.currentSettings = newSettings;
			closePopup();
		}

		function reset() {
			vm.frequency 	= vm.currentSettings.frequency;
			vm.period 		= vm.currentSettings.period;
			vm.homeCharger 	= vm.currentSettings.homeCharger;
			vm.destCharger	= vm.currentSettings.destCharger;
			vm.destType 	= vm.currentSettings.destType;
			vm.destHours 	= vm.currentSettings.destHours;
			vm.destPrice 	= vm.currentSettings.destPrice;
			vm.destPer 		= vm.currentSettings.destPer;
		}

		function cancel() {
			reset();
			closePopup();
		}

		function changePeriod() {
			var limit, frequencies = [{label:'1 time', value:1}];
			if(vm.period === 'week') {
				limit = 7;
			}
			else if(vm.period === 'month') {
				limit = 31;
			}
			else if(vm.period === 'year') {
				limit = 365;
			}

			for(var i = 2; i <= limit; i++) {
				frequencies.push({label: i+' times', value:i});
			}
			vm.frequencies = frequencies;
		}
	}

})();
