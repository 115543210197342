(function() {
	'use strict';

	angular
		.module('instructions')
		.factory('Slides', Slides);

	Slides.$inject = [];

	function Slides() {

		var slides = [
			{key: 'welcome', title: 'Welcome'},
			{key: 'prices', title: 'Fuel Prices'},
			{key: 'home', title: 'Home'},
			{key: 'distance', title: 'Distance Bar'},
			{key: 'cars', title: 'Car Manager'},
			{key: 'commute', title: 'Commute Settings'},
			{key: 'charger', title: 'Charger Settings'},
			{key: 'results', title: 'Results'},
			{key: 'advanced', title: 'Advanced Options'},
			{key: 'terms', title: 'Terminology'},
			{key: 'feedback', title: 'Feedback'},
		];

		var currentSlide = 0;

		var factory = {
			getSlides: getSlides,
			setSelected: setSelected,
			getSelected: getSelected,
			getPrevious: getPrevious,
			getNext: getNext,
			goToFirst: goToFirst,
		};

		return factory;

		////////////

		function getSlides() {
			return slides;
		}

		function setSelected(key) {
			for(var i = 0, len = slides.length; i < len; i++) {
				if(slides[i].key === key) {
					currentSlide = i;
				}
			}
		}

		function getSelected() {
			return slides[currentSlide].key;
		}

		function getPrevious() {
			if(currentSlide-1 < 0) {
				return slides[slides.length-1].key;
			}

			return slides[currentSlide-1].key;
		}

		function getNext() {
			if(currentSlide+1 > slides.length-1) {
				return slides[0].key;
			}

			return slides[currentSlide+1].key;
		}

		function goToFirst() {
			currentSlide = 0;
		}


	}

})();
