(function() {
	'use strict';

	angular
		.module('core')
		.factory('Calcs', Calcs);

	Calcs.$inject = ['Settings'];

	function EV(range) {
		this.range = range;
		this.soc = range;
		this.charges = [{source: 'starting', distance: range}];
		this.sources = {
			home: 0,
			work: 0
		};
	}

	EV.prototype.range = null;
	EV.prototype.soc = null;
	EV.prototype.charges = null;
	EV.prototype.sources = null;

	EV.prototype.charge = function(source, distance) {
		this.charges.push({
			source: source,
			distance: distance,
		});

		this.soc+= distance;
	};

	EV.prototype.drive = function(distance) {
		this.deplete(distance);
	};

	EV.prototype.deplete = function(distance) {
		var remaining = distance,
			current,
			source;

		// update soc
		this.soc-= distance;

		// attribute distance to correct source
		while(remaining) {
			current = this.charges.length-1;
			source = this.charges[current].source;

			if(remaining <= this.charges[current].distance) {
				this.sources[source]+= remaining;
				this.charges[current].distance-= remaining;
				remaining = 0;
			}
			else {
				remaining-= this.charges[current].distance;
				this.sources[source]+= this.charges[current].distance;
				this.charges.pop();
			}
		}
	};

	EV.prototype.calculate = function(roundTrip, numTrips, workChargerCapacity, homeCharging) {
		var oneWay = roundTrip / 2,
			sufficientRange = false;

		// no charging available at all
		if(homeCharging === false && workChargerCapacity === 0 && this.range >= roundTrip * numTrips) {

			sufficientRange = true;
		}

		// charging only at home or only at work
		else if(((homeCharging === true && workChargerCapacity === 0) ||
			(homeCharging === false && workChargerCapacity > 0)) &&
			this.range >= roundTrip) {

			sufficientRange = true;
		}

		// charging at both home and work
		else if(homeCharging === true && workChargerCapacity > 0 && this.range >= oneWay) {

			sufficientRange = true;
		}

		// Can we make the trip with the given vehicle and charging?
		if(sufficientRange === true) {

			for(var i = 1; i <= numTrips; i++) {
				// trip
				this.drive(oneWay);

				// charge if available
				if(workChargerCapacity > 0) {

					// excess/max capacity, charge to full
					if(workChargerCapacity >= (this.range - this.soc)) {
						this.charge('work', this.range - this.soc);
					}
					// partial charge
					else {
						this.charge('work', workChargerCapacity);
					}
				}

				// return trip
				this.drive(oneWay);

				// recharge at home
				if(homeCharging === true) {
					this.charge('home', this.range - this.soc);
				}
			}
		}

		return sufficientRange;
	};

	function PHEV(range, cd) {
		this.range = range;
		this.soc = range;
		this.cd = cd;
		this.charges = [{source: 'starting', distance: range}];
		this.sources = {
			home: 0,
			work: 0
		};
		this.blendedVolume = 0;
		this.fuelDistance = 0;
	}

	PHEV.prototype.range = null;
	PHEV.prototype.soc = null;
	PHEV.prototype.cd = null;
	PHEV.prototype.charges = null;
	PHEV.prototype.sources = null;
	PHEV.prototype.blendedVolume = null;
	PHEV.prototype.fuelDistance = null;

	PHEV.prototype.charge = function(source, distance) {
		if(!this.sources[source]) {
			this.sources[source] = 0;
		}

		this.charges.push({
			source: source,
			distance: distance,
		});

		this.soc+= distance;
	};

	PHEV.prototype.drive = function(distance) {
		// battery available?
		if(this.soc) {
			// all on battery
			if(this.soc >= distance) {
				this.deplete(distance);
			}
			// use up battery, remainder is fuel
			else {
				this.fuelDistance+= distance - this.soc;
				this.deplete(this.soc);
			}
		}
		// all fuel
		else {
			this.fuelDistance+= distance;
		}
	};

	PHEV.prototype.deplete = function(distance) {
		var remaining = distance,
			current,
			source;

		if(this.cd) {
			this.blendedVolume+= distance * this.cd;
		}

		// update soc
		this.soc-= distance;

		// attribute distance to correct source
		while(remaining) {
			current = this.charges.length-1;
			source = this.charges[current].source;

			if(remaining <= this.charges[current].distance) {
				this.sources[source]+= remaining;
				this.charges[current].distance-= remaining;
				remaining = 0;
			}
			else {
				remaining-= this.charges[current].distance;
				this.sources[source]+= this.charges[current].distance;
				this.charges.pop();
			}
		}
	};

	PHEV.prototype.calculate = function(roundTrip, numTrips, workChargerCapacity, homeCharging) {
		var oneWay = roundTrip / 2;

		for(var i = 1; i <= numTrips; i++) {
			// trip
			this.drive(oneWay);

			// Charge if available
			if(workChargerCapacity) {

				// excess/max capacity, charge to full
				if(workChargerCapacity >= (this.range - this.soc)) {
					this.charge('work', this.range - this.soc);
				}
				// partial charge
				else {
					this.charge('work', workChargerCapacity);
				}
			}

			// return trip
			this.drive(oneWay);

			// recharge at home
			if(homeCharging === true) {
				this.charge('home', this.range - this.soc);
			}

		}

		return true;
	};

	function Calcs(Settings) {

		var costData, EVLimits, roundTrip;

		var factory = {
			calcCostData: calcCostData,
			getCostData: getCostData,
			getCarTotals: getCarTotals,

			calcEVLimits: calcEVLimits,
			getEVLimits: getEVLimits,

			calcRoundTrip: calcRoundTrip,
			getRoundTrip: getRoundTrip,

			getNumTrips: getNumTrips,

			ready: ready,
		};

		return factory;

		function setCostData(data) {
			costData = data;
		}

		function getCostData() {
			return costData;
		}

		function setEVLimits(limits) {
			EVLimits = limits;
		}

		function getEVLimits() {
			return EVLimits;
		}

		function setRoundTrip(distance) {
			roundTrip = distance;
		}

		function getRoundTrip() {
			return roundTrip;
		}

		function ready() {
			if(costData && EVLimits && roundTrip) {
				return true;
			}

			return false;
		}

		function calcCostData() {
			var cars = Settings.getCars();
			var prices = Settings.getPrices();
			var numTrips = getNumTrips();
			var roundTrip = getRoundTrip();
			var homeCharging = Settings.getCommute().homeCharger;
			var data = [];

			if(!prices || !cars || !roundTrip) {
				return;
			}

			for(var i = 0, len = cars.length; i < len; i++) {

				var fuelDistance = 0,
					blendedVolume = 0,
					homeElecDistance = 0,
					workElecDistance = 0,
					withinRange = true;

				if(cars[i].type === 'EV' || cars[i].type === 'Plug-in Hybrid') {
					var pev;
					var workChargerCapacity = calcWorkChargerCapacity(cars[i]);

					if(cars[i].type === 'EV') {
						pev = new EV(cars[i].elecRange);
					}
					else {
						pev = new PHEV(cars[i].elecRange, cars[i].cdEconomy);
					}

					withinRange = pev.calculate(roundTrip, numTrips, workChargerCapacity, homeCharging);

					if(withinRange === true) {
						if(cars[i].type === 'Plug-in Hybrid') {
							blendedVolume = pev.blendedVolume;
							fuelDistance = pev.fuelDistance;
						}

						homeElecDistance = pev.sources.home;

						if(workChargerCapacity) {
							workElecDistance = pev.sources.work;
						}
					}
				}
				else {
					fuelDistance = getRoundTrip() * numTrips;
				}

				// Total volume of fuel used for all trips
				var fuelVolume = ((fuelDistance * cars[i].fuelEconomy) + blendedVolume);
				var fuelPrice = getFuelPrice(cars[i].fuelType);

				// Total kwh charged at work for all trips
				var workElecKwh = workElecDistance * cars[i].elecEconomy;
				var workElecTotal = workElecDistance ? calcChargerCost(workElecDistance, cars[i]) : 0;

				// Total kwh charged at home for all trips
				var homeElecKwh = homeElecDistance * cars[i].elecEconomy;
				var homeElecPrice = Settings.getPrices().electricity;

				data.push({
					name: cars[i].make+' '+cars[i].model+', '+cars[i].year,

					fuelPrice: fuelPrice,
					fuelTotal: fuelVolume * fuelPrice,
					fuelVolume: fuelVolume,

					workElecTotal: workElecTotal,
					workElecKwh: workElecKwh,
					workElecPrice: workElecKwh ? workElecTotal / workElecKwh : 0,

					homeElecTotal: homeElecKwh * homeElecPrice,
					homeElecKwh: homeElecKwh,
					homeElecPrice: homeElecPrice,

					withinRange: withinRange,
				});
			}

			setCostData(data);
		}

		function getNumTrips() {
			var commute = Settings.getCommute();

			if(commute.period === 'year') {
				return commute.frequency;
			}
			else if(commute.period === 'month') {
				return commute.frequency * 12;
			}
			else if(commute.period === 'week') {
				return commute.frequency * 52;
			}
		}

		function calcChargerCost(distance, car) {
			var commute = Settings.getCommute();

			if(commute.destPer === 'hour') {
				if(commute.destType === 'lvl1') {
					// distance * ((kwh per unit of distance) / 1.44 kw per hour @ lvl1) * price per hour
					// distance * hours per unit of distance * price per hour
					// hours * price per hour
					// price
					return distance * (car.elecEconomy / 1.44) * commute.destPrice;
				}
				else  {
					// distance * (hours to charge @ lvl2 / range) * price per hour
					// distance * hours per unit of distance * price per hour
					// hours * price per hour
					// price
					return distance * (car.lvl2chargeTime / car.elecRange) * commute.destPrice;
				}
			}
			else {
				// distance * (kwh per unit of distance) * price per kwh
				// kwh * price per kwh
				// price
				return distance * car.elecEconomy * commute.destPrice;
			}
		}

		function getFuelPrice(fuelType) {
			var prices = Settings.getPrices();

			switch(fuelType) {
				case 'Regular Gasoline':
					return prices.regular;
				case 'Midgrade Gasoline':
					return prices.super;
				case 'Premium Gasoline':
					return prices.premium;
				case 'Diesel':
					return prices.diesel;
				default:
					return 0;
			}
		}

		function calcEVLimits() {
			var cars = Settings.getCars();

			if(cars) {
				var limits = [];
				for(var i = 0, len = cars.length; i < len; i++) {
					if(cars[i].type === 'EV') {
						limits.push({
							name: cars[i].make+' '+cars[i].model+', '+cars[i].year,
							range: cars[i].elecRange
						});
					}
				}
				setEVLimits(limits);
			}
		}

		function calcRoundTrip() {
			var route = Settings.getRoute();

			if(route) {
				var total = 0;
				for (var i = 0, len = route.routes[0].legs.length; i < len; i++) {
					total += route.routes[0].legs[i].distance.value;
				}

				if(Settings.getCurrency() === 'USD') {
					//m -> feet -> miles
					setRoundTrip((total * 3.28084) / 5280.0);
				}
				else {
					//m -> km
					setRoundTrip(total/1000);
				}
			}
		}

		function getCarTotals() {
			var totals = [];

			if(costData) {

				for(var i = 0, len = costData.length; i < len; i++) {
					totals.push({
						name: costData[i].name,
						total: costData[i].fuelTotal + costData[i].homeElecTotal + costData[i].workElecTotal,
						fuel: costData[i].fuelTotal,
						electricity: costData[i].homeElecTotal + costData[i].workElecTotal,
						withinRange: costData[i].withinRange,
					});
				}

				return totals;
			}
		}

		function calcWorkChargerCapacity(car) {
			var commute = Settings.getCommute();
			var chargeTime;

			if(commute.destCharger && commute.destHours) {

				// Charging time depending on charger type
				if(commute.destType === 'lvl1') {
					// (maximum miles * kwh / mi) / lvl1 charge rate
					// kwh in a full charge / lvl1 charge rate
					// hours to fully charge
					chargeTime = (car.elecRange * car.elecEconomy) / 1.44;
				}
				else if(commute.destType === 'lvl2') {
					chargeTime = car.lvl2chargeTime;
				}

				// (maximum miles / time to charge) * hours parked
				// miles charged per hours * hours parked
				// miles of charging capacity available
				return (car.elecRange / chargeTime) * commute.destHours;
			}

			return 0;
		}
	}
})();