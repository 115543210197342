(function() {
	'use strict';

	angular
		.module('locations')
		.config(Config);

	Config.$inject = ['$routeProvider'];

	function Config($routeProvider) {
		$routeProvider
			.when('/locations', {
				templateUrl: './modules/locations/views/locations.client.view.html',
				controller: 'Locations',
				controllerAs: 'vm'
			})
			.when('/locations/start', {
				templateUrl: './modules/locations/views/start.client.view.html',
				controller: 'Picker',
				controllerAs: 'vm'
			})
			.when('/locations/end', {
				templateUrl: './modules/locations/views/end.client.view.html',
				controller: 'Picker',
				controllerAs: 'vm'
			})
			.when('/locations/results', {
				template: '',
				controller: 'Static',
				controllerAs: 'vm'
			});
	}

})();
