(function() {
	'use strict';

	angular
		.module('core')
		.directive('phevCostGraph', phevCostGraph);

	phevCostGraph.$inject = ['d3Service'];

	function phevCostGraph(d3Service) {

		var directive = {
			restrict: 'EA',
			link: link,
			scope: {
				data: '=',
				label: '@',
				onClick: '&',
				symbol: '='
			}
		};

		return directive;

		////////////

		function link(scope, element) {
			d3Service.d3().then(function(d3) {

				var margin = {top: 20, right: 20, bottom: 1, left: 5};

				//Create root element
				var svg = d3.select(element[0])
							.append('svg')
								.attr('class', 'barGraph');

				//Watch for resizing (window / angular) or data changing
				window.onresize = function() {
					scope.$apply();
				};
				scope.$watch(function() {
					return angular.element(window)[0].innerWidth;
				}, function(newVal, oldVal) {
					if(newVal !== oldVal) {
						scope.render(scope.data, scope.symbol);
					}
				});
				scope.$watch('data', function() {
					scope.render(scope.data, scope.symbol);
				}, true);

				//Render the chart
				scope.render = function(data, symbol) {

					// Update height
					svg.attr('height', element.parent().prop('height'));

					// Setup sizing
					var height = svg.nodes()[0].getBoundingClientRect().height - margin.top - margin.bottom,
						width = svg.nodes()[0].getBoundingClientRect().width - margin.left - margin.right;

					// Don't redraw if there are negative values on the height or width (hidden)
					if(! (height > 0 && width > 0)) {
						return;
					}

					// Clear existing
					svg.selectAll('*').remove();

					// Wrapper to ensure margins
					var cont = svg.append('g')
						.attr('transform', 'translate('+margin.left+','+margin.top+')');

					// Calculate stacked values
					var stack = d3.stack()
								  .keys(['electricity', 'fuel']);
					var stacked = stack(data);
					
					// Find max value (total of elec and gas)
					var xMax = d3.max(data, function(group) {
						return group.total;
					});

					// Setup scales
					var xScale = d3.scaleLinear()
									.domain([0, xMax])
									.range([0, width]);

					var yScale = d3.scaleBand()
									.domain([0,1,2,3])
									.rangeRound([0, height])
									.padding([0.35]);

					var colors = d3.scaleOrdinal()
									.range(['#8DC63F', '#F0CD65']);

					var xFormatter = d3.format(',.0f');

					// Setup axes
					var xAxis = d3.axisTop()
									.scale(xScale)
									.ticks(5)
									.tickFormat(function(d) { return symbol+xFormatter(d); });

					// Insert x axis
					cont.append('g')
						.attr('class', 'axis')
						.call(xAxis);

					// Figure out which vehicles are beyond their range
					var horizontalGridLines = [];
					var vehiclesBeyondRange = [];
					for(var j = 0; j < data.length; j++) {
						if(data[j].withinRange === true) {
							horizontalGridLines.push(j);
						}
						else {
							vehiclesBeyondRange.push(j);
						}
					}

					//Insert horizontal grid lines
					cont.append('g')
						.attr('class', 'grid')
						.call(d3.axisRight()
								.scale(yScale)
								.tickSize(width, 0, 0)
								.tickValues(horizontalGridLines)
								.tickFormat(''));

					//Insert vertical grid lines
					cont.append('g')
						.attr('class', 'grid')
						.call(d3.axisTop()
								.scale(xScale)
								.ticks(5)
								.tickSize(-height, 0, 0)
								.tickFormat(''));

					// Insert a group for each series
					var groups = cont.selectAll('g.series')
									.data(stacked).enter()
										.append('g')
										.attr('class', 'series')
										.style('fill', function(d, i) { return colors(i); });

					// Insert a rect for each car in the series
					var rect = groups.selectAll('rect')
						.data(function(d) { return d; }).enter()
							.append('rect')
							.attr('x', 0)
							.attr('y', function(d, i) { return yScale(i); })
							.attr('height', function() { return yScale.bandwidth(); })
							.attr('width', 0);

					// Animate the bar
					rect.transition()
							.delay(function(d,i) { return i * 300; })
							.duration(300)
							.attr('width', function(d) { return xScale(d[1]-d[0]); })
							.attr('x', function(d) { return xScale(d[0]); });

					// Add text for vehicles without enough range to complete the trip
					cont.select('g.series').selectAll('text')
						.data(vehiclesBeyondRange).enter()
							.append('text')
							.text(function(d){
								var text = 'Distance past range of '+data[d].name;

								if(text.length > 40) {
									return text.substring(0, 39).trim()+ '...';
								}

								return text;
							})
							.style('fill', '#333')
							.style('font-size', '12px')
							.style('opacity', '0.8')
							.attr('x', 5)
							.attr('y', function(d) { return yScale(d) + (yScale.bandwidth() / 2) + 3; });

				};
			});
		}
	}
})();
