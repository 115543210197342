(function() {
	'use strict';

	angular
		.module('optin')
		.factory('OptIn', OptIn);

	OptIn.$inject = ['$http'];

	function OptIn($http) {

		var factory = {
			subscribe: subscribe
		};

		return factory;

		////////////

		function subscribe(email, interest) {
			var data = {
				email: email,
				interest: interest
			};

			return $http.post('/api/subscribe', data);
		}
	}

})();
