(function() {
	'use strict';

	angular
		.module('export')
		.controller('Export', ExportController);

	ExportController.$inject = ['$rootScope', '$location', 'Export', 'Calcs'];

	function ExportController($rootScope, $location, Export, Calcs) {

		var vm = this;

		vm.closePopup = closePopup;

		activate();

		////////////

		function activate() {
			if(Calcs.ready() === true) {
				vm.settingsData = Export.getSettingsData();
				vm.carData = Export.getCarData();
			}
			else {
				closePopup();
			}
		}

		function closePopup() {
			$location.path('/');
		}
	}

})();
