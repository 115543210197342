(function() {
	'use strict';

	angular
		.module('optin')
		.controller('OptIn', OptInController);

	OptInController.$inject = ['$rootScope', '$location', 'OptIn'];

	function OptInController($rootScope, $location, OptIn) {

		var vm = this;

		vm.closePopup = closePopup;
		vm.subscribe = subscribe;

		////////////

		function closePopup() {
			$location.path('/');
		}

		function subscribe() {
			if(vm.email && vm.interest) {
				OptIn.subscribe(vm.email, vm.interest).then(subscribeSuccess, subscribeFailure);
			}
		}

		function subscribeSuccess() {
			vm.message = 'Subscribed!';
			reset();
		}

		function subscribeFailure() {
			vm.message = 'Failed to susbcribe';
			reset();
		}

		function reset() {
			vm.email = '';
			vm.interest = '';
		}
	}

})();
