(function(){
	'use strict';

	angular
		.module('core')
		.directive('phevCostLegend', phevCostInfo);

	phevCostInfo.$inject = [];

	function phevCostInfo() {

		var directive = {
			restrict: 'E',
			templateUrl: './modules/results/views/costLegend.client.view.html',
			scope: {
				'prices': '=',
				'state': '=',
				'symbol': '='
			}
		};

		return directive;

		/////////////////

	}

})();
