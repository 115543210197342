(function() {
	'use strict';

	angular
		.module('locations')
		.controller('Locations', LocationsController);

	LocationsController.$inject = ['$rootScope', '$scope', '$location', 'Settings'];

	function LocationsController($rootScope, $scope, $location, Settings) {

		var vm = this;

		$rootScope.init.then(activate);
		$scope.$watch(getOrigin, updateOrigin, true);
		$scope.$watch(getDest, updateDest, true);

		vm.changeOrigin = changeOrigin;
		vm.changeDest = changeDest;
		vm.cancel = cancel;

		////////////

		function getOrigin() {
			return Settings.getOrigin();
		}

		function updateOrigin(newVal) {
			if(newVal) {
				vm.origin = newVal;
			}
		}

		function getDest() {
			return Settings.getDest();
		}

		function updateDest(newVal) {
			if(newVal) {
				vm.dest = newVal;
			}
		}

		function activate() {
			vm.origin = Settings.getOrigin();
			vm.dest = Settings.getDest();
		}

		function changeOrigin() {
			$location.path('/locations/start');
		}

		function changeDest() {
			$location.path('/locations/end');
		}

		function cancel() {
			$location.path('/');
		}
	}

})();
