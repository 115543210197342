(function() {
	'use strict';

	angular
		.module('prices')
		.config(Config);

	Config.$inject = ['$routeProvider'];

	function Config($routeProvider) {
		$routeProvider
			.when('/prices', {
				templateUrl: './modules/prices/views/prices.client.view.html',
				controller: 'Prices',
				controllerAs: 'vm'
			});
	}

})();