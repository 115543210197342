(function() {
	'use strict';

	angular
		.module('core')
		.controller('NavBar', NavBarController);

	NavBarController.$inject = ['$scope', 'Settings', '$location', '$rootScope'];

	function NavBarController($scope, Settings, $location, $rootScope) {

		var vm = this;

		vm.navCollapsed = true;
		vm.destDisabled = true;

		vm.closeNav = closeNav;

		$scope.$watch(getOrigin, updateDisabled, true);
		$rootScope.$on('$locationChangeSuccess', updateActive);

		activate();

		////////////

		function activate() {
			updateActive();
		}

		function getOrigin() {
			return Settings.getOrigin();
		}

		function updateDisabled(newVal) {
			vm.destDisabled = newVal ? false : true;
		}

		function closeNav() {
			vm.navCollapsed = true;
		}

		function updateActive() {
			var currPath = $location.path();

			vm.start = false;
			vm.end = false;

			if(currPath === '/locations/start') {
				vm.start = true;
			}
			else if(currPath === '/locations/end') {
				vm.end = true;
			}
		}

	}

})();
