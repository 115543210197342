(function() {
	'use strict';

	var dependencies = ['ngRoute', 'core', 'uiGmapgoogle-maps'];

	angular.module('locations', dependencies).config(Config);

	Config.$inject = ['uiGmapGoogleMapApiProvider'];

	function Config(uiGmapGoogleMapApiProvider) {
		uiGmapGoogleMapApiProvider.configure({
	        key: 'AIzaSyB7AFKwwwi_V-Nz-e1Ywp0CFb8VDUbrLK8',
	        libraries: 'places',
	        v: 'weekly',
	    });
	}

})();
