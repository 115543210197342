(function() {
	'use strict';

	angular
		.module('prices')
		.controller('Prices', PricesController);

	PricesController.$inject = ['$rootScope', '$location', 'Settings', 'Prices', 'Converter'];

	function PricesController($rootScope, $location, Settings, Prices, Converter) {

		var vm = this;

		vm.update = update;
		vm.changeState = changeState;
		vm.cancel = cancel;
		vm.changeCurrency = changeCurrency;

		$rootScope.init.then(activate);

		////////////

		function changeCurrency() {

			vm.loading = true;

			if(vm.code !== 'USD') {
				vm.state = 'custom';
			}

			Converter.initRates().then(function() {
				vm.regular = Converter.convert(vm.regular, vm.code);
				vm.super = Converter.convert(vm.super, vm.code);
				vm.premium = Converter.convert(vm.premium, vm.code);
				vm.diesel = Converter.convert(vm.diesel, vm.code);
				vm.electricity = Converter.convert(vm.electricity, vm.code, false);
				vm.destPrice = Converter.convert(vm.destPrice, vm.code, false);

				Converter.setCurrency(vm.code);
				vm.currency = Converter.getCurrency();
				vm.loading = false;
			});
		}

		function update() {
			if(vm.originalPrices.regular !== vm.regular ||
				vm.originalPrices.super !== vm.super ||
				vm.originalPrices.premium !== vm.premium ||
				vm.originalPrices.diesel !== vm.diesel ||
				vm.originalPrices.electricity !== vm.electricity) {

				Settings.setPrices({
					regular: vm.regular,
					super: vm.super,
					premium: vm.premium,
					diesel: vm.diesel,
					electricity: vm.electricity,
				});

				vm.state = 'custom';
			}
			else {
				Settings.setPrices(vm.originalPrices);
			}

			var commute = Settings.getCommute();
			if(vm.destPrice !== commute.destPrice) {
				commute.destPrice = vm.destPrice;
				Settings.setCommute(commute);
			}

			Settings.setCurrency(vm.code);
			Settings.setState(vm.state);
			cancel();
		}

		function reset() {
			vm.regular = vm.originalPrices.regular;
			vm.super = vm.originalPrices.super;
			vm.premium = vm.originalPrices.premium;
			vm.diesel = vm.originalPrices.diesel;
			vm.electricity = vm.originalPrices.electricity;
		}

		function cancel() {
			$location.path('/');
		}

		function changeState() {
			vm.loading = true;
			Prices.getPrices(vm.state).then(function(results){
				vm.originalPrices = results;
				reset();
				vm.loading = false;
			});
		}

		function activate() {
			vm.state = Settings.getState();
			vm.code = Settings.getCurrency();
			vm.originalPrices = Settings.getPrices();
			vm.destPrice = Settings.getCommute().destPrice;
			reset();

			Converter.setCurrency(vm.code);
			vm.currencies = Converter.getCurrencies();
			vm.currency = Converter.getCurrency();

			vm.loading = false;
		}
	}

})();
