(function() {
	'use strict';

	angular
		.module('instructions')
		.controller('Instructions', InstructionsController);

	InstructionsController.$inject = ['$rootScope', '$routeParams', '$location', 'Slides', 'Feedback'];

	function InstructionsController($rootScope, $routeParams, $location, Slides, Feedback) {

		var vm = this;

		vm.changeSlide = changeSlide;
		vm.previous = previous;
		vm.next = next;
		vm.closePopup = closePopup;
		vm.sendFeedback = sendFeedback;

		$rootScope.init.then(activate);

		////////////

		function displaySlide() {
			vm.selected = Slides.getSelected();
			vm.template = 'modules/instructions/views/'+vm.selected+'.client.view.html';
		}

		function activate() {
			vm.slides = Slides.getSlides();

			// Go to the correct slide
			if($routeParams.slide) {
				Slides.setSelected($routeParams.slide);
			}
			else {
				Slides.goToFirst();
			}

			displaySlide();

			// Check feedback status
			if(Feedback.isSent()) {
				if(Feedback.isError()) {
					feedbackError();
				}
				else {
					feedbackSuccess();
				}
			}
		}

		function closePopup() {
			$location.path('/');
		}

		function changeSlide() {
			$location.path('/instructions/'+vm.selected);
		}

		function previous() {
			Slides.setSelected(Slides.getPrevious());
			displaySlide();
		}

		function next() {
			Slides.setSelected(Slides.getNext());
			displaySlide();
		}

		function sendFeedback() {
			if(vm.feedback && !Feedback.isError()) {
				Feedback.send(vm.feedback).then(feedbackSuccess, feedbackError);
            }
		}

		function feedbackSuccess() {
			vm.message = 'Feedback sent successfully!';
		}

		function feedbackError() {
			vm.message = 'Error sending feedback!';
		}
	}

})();
